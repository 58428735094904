import React, { ButtonHTMLAttributes, forwardRef, memo, PropsWithChildren, Ref } from 'react';
import styled from '@emotion/styled';

// styles
import { media, theme } from 'styles/theme';

const Button = (
  {
    type = 'button',
    className,
    children,
    disabled,
    background = '--bt-y-1',
    shadow,
    ...rest
  }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
  ref?: Ref<HTMLButtonElement>,
) => {
  return (
    <>
      <StyledButton
        type={type}
        className={className}
        disabled={disabled}
        ref={ref}
        shadow={shadow}
        background={background}
        {...rest}
      >
        {children}
      </StyledButton>
    </>
  );
};

const shadows = {
  orange:
    '0px -8px 0px 0px rgba(195, 73, 65, 0.56) inset, 0px 0px 32px 0px rgba(240, 97, 88, 0.52)',
  green:
    '0px -8px 0px 0px rgba(139, 163, 0, 0.56) inset, 0px 0px 32px 0px rgba(229, 255, 78, 0.52)',
  purple:
    '0px -8px 0px 0px rgba(54, 0, 124, 0.56) inset, 0px 0px 32px 0px rgba(154, 73, 255, 0.56)',
} as const;

const StyledButton = styled.button<{
  shadow?: keyof typeof shadows;
  background: ButtonBackgroundKey;
}>`
  color: #fff;
  text-align: center;
  font-family: ThaleahFat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 36px */
  display: flex;
  padding: 16px 40px 24px 40px;
  align-items: center;
  gap: 16px;
  border: none;
  border-radius: 20px;
  box-shadow: ${({ shadow }) => (shadow ? shadows[shadow] : 'none')};
  background: ${({ background, theme }) => theme.colors[background]};
  cursor: pointer;
  z-index: 2;
  transition: 0.1s ease;
  &:disabled {
    color: #919396;
    background: #eaecf1;
    box-shadow: 0px -8px 0px 0px #b9bbc1 inset;
    cursor: not-allowed;
  }

  ${({ shadow }) =>
    shadow &&
    `&:active {
    box-shadow: none;
    padding: 16px 40px 16px 40px;
    transform: translateY(8px);
  }`}

  ${media.xs} {
    font-size: 24px;
  }
`;

type ButtonBackgroundKey = keyof Pick<
  typeof theme.colors,
  '--bt-y-1' | '--bt-y-2' | '--bt-o-1' | '--bt-o-2' | '--bt-p-1' | '--bt-p-2'
>;

interface ButtonProps extends PropsWithChildren {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
  background?: ButtonBackgroundKey;
  shadow?: keyof typeof shadows;
}
export default memo(forwardRef(Button));
