import styled from '@emotion/styled';

import { Link } from 'react-router-dom';
import { media } from '../../styles/theme';
import { S3_ASSET_HOST_URL } from 'constants/url';
import { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';

const Header = () => {
  const lastScrollPosition = useRef(0);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const handleScroll = throttle(() => {
    const { y } = document.body.getBoundingClientRect();
    const yOffset = y === 0 ? 0 : y * -1;
    setIsHeaderVisible(yOffset < 100 || lastScrollPosition.current > yOffset);
    lastScrollPosition.current = yOffset;
  }, 300);

  useEffect(() => {
    // 페이지 이동 시 헤더를 다시 노출시킴
    // dispatch(doSetIsShowHeader(true))

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderContainer isHeaderVisible={isHeaderVisible}>
      <HeaderLogoLink to="/">
        <img src={`${S3_ASSET_HOST_URL}/front/image/header/header-logo.webp`} />
      </HeaderLogoLink>
      <Link to="https://discord.gg/bitportal" target="_blank">
        <DiscordIcon src="/icons/discord-icon.svg" />
      </Link>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.header<{ isHeaderVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 88px;
  padding: 24px 40px;
  background: rgba(4, 5, 56, 0.8);
  backdrop-filter: blur(16px);
  box-sizing: border-box;

  transform: ${props =>
    props.isHeaderVisible ? 'translate3d(0, 0, 0)' : 'translate3d(0, -88px, 0)'};

  transition: transform 0.21s;

  ${media.xs} {
    height: 60px;
    padding: 16px;
  }
`;

const HeaderLogoLink = styled(Link)`
  width: 120px;
  height: 30px;

  ${media.xs} {
    width: 80px;
    height: 20px;
  }
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const DiscordIcon = styled.img`
  display: block;
  width: auto;
  height: auto;

  ${media.xs} {
    width: 24px;
  }
`;
