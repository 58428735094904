import { css } from '@emotion/react';
import GridContainer from 'components/common/GridContainer';
import GridItem from 'components/common/GridItem';
import { media, theme } from 'styles/theme';
import BaseContainer from '../common/BaseContainer';
import { S3_ASSET_HOST_URL } from 'constants/url';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { detectMobile } from '../../utils/common/detectEnviorment';

const Section6 = () => {
  const isMobile = detectMobile();
  return (
    <section
      css={css`
        background: #2c1856;
        position: relative;
        height: 3776px;
        overflow: hidden;

        ${media.md} {
          height: 1874px;
        }

        ${media.sm} {
          height: 1696px;
        }

        ${media.xs} {
          height: 1596px;
        }
      `}
    >
      <ParallaxProvider>
        <GridContainer
          justifyContents="center"
          alignItems="center"
          css={css`
            max-width: 1280px;
            margin: 0 auto 200px;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section6/section6-1.gif');
            background-size: 100% 100%;
            background-position: center center;
            padding: 120px 0 120px;

            ${media.lg} {
              background-size: 1326px 437px;
              padding: 120px 0 120px;
            }

            ${media.md} {
              background-size: 950px 263px;
              margin: 0 auto 40px;
              padding: 100px 0 45px;
            }

            ${media.sm} {
              background-size: 805px 223px;
              padding: 52px 0 53px;
            }
          `}
        >
          <GridItem
            md={12}
            css={css`
              margin: 0;
              text-align: center;
              font-family: Tomorrow;
              font-size: 72px;
              font-style: normal;
              font-weight: 700;
              line-height: 135%; /* 97.2px */
              background: linear-gradient(90deg, #deff82 2.99%, #0f6 119.5%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              padding: 0 16px;

              ${media.md} {
                font-size: 40px;
                margin-bottom: 24px;
              }
            `}
          >
            Bit Portal Logic
          </GridItem>
          <GridItem
            md={4}
            sm={6}
            xs={12}
            css={css`
              margin: 0;
              max-width: 848px;
              color: #ded2ff;
              text-align: center;
              font-family: Poppins;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 175%; /* 42px */
              padding: 0 16px;

              ${media.md} {
                font-size: 12px;
              }

              ${media.xs} {
                font-size: 15px;
              }
            `}
          >
            Bit Portal mints duplicate tokens on each chain, but ensures that only one token is
            unlocked and free to move.
          </GridItem>
        </GridContainer>

        <BaseContainer>
          <div
            css={css`
              width: 50%;
              background-image: url('${S3_ASSET_HOST_URL}/front/image/section6/section6-2.gif');
              background-size: contain;
              background-position: bottom;
              padding: 20px 0 0 30px;

              ${media.md} {
                background-position: -100px bottom;
              }

              ${media.sm} {
                background-position: -210px top;
              }

              ${media.xs} {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0;
                background-position: center top;
              }
            `}
          >
            <div
              css={css`
                color: #fff;
                font-family: Tomorrow;
                font-size: 96px;
                font-style: italic;
                font-weight: 700;
                line-height: 100%; /* 96px */
                letter-spacing: -3.84px;
                transform: rotate(-6.88deg);

                ${media.md} {
                  font-size: 40px;
                }

                ${media.xs} {
                  transform: rotate(-6.88deg) translateX(-40px);
                  text-align: center;
                }
              `}
            >
              Why
            </div>
            <div
              css={css`
                color: #88fcf5;
                font-family: Tomorrow;
                font-size: 96px;
                font-style: italic;
                font-weight: 700;
                line-height: 100%; /* 96px */
                letter-spacing: -3.84px;
                transform: rotate(-6.877deg) translateX(55px);

                ${media.md} {
                  font-size: 40px;
                }
                ${media.xs} {
                  transform: rotate(-6.877deg) translateX(0);
                  text-align: center;
                }
              `}
            >
              Switch?
            </div>
          </div>

          <GridContainer
            css={css`
              margin: 139px 0 150px;

              ${media.xs} {
                margin: 88px 0;
              }
            `}
            justifyContents={'space-between'}
            alignItems={'center'}
          >
            <GridItem xl={4}></GridItem>
            <GridItem
              xl={8}
              lg={8}
              sm={9}
              xs={12}
              css={css`
                position: relative;
              `}
            >
              <Parallax speed={10}>
                <img
                  src={`${S3_ASSET_HOST_URL}/front/image/section6/absolute6-1.webp`}
                  alt="absolute6-1"
                  css={css`
                    position: absolute;
                    top: -386px;
                    right: 0;
                    width: 163px;
                    height: 386px;

                    ${media.md} {
                      top: -233px;
                      width: 92px;
                      height: 233px;
                    }

                    ${media.sm} {
                      width: 85px;
                      height: 202px;
                    }

                    ${media.xs} {
                      display: none;
                    }
                  `}
                />
              </Parallax>
              <div
                css={css`
                  align-self: stretch;
                  font-family: Poppins;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 155%; /* 62px */
                  background: radial-gradient(826.81% 141.42% at 0% 0%, #fff 18.38%, #87d4ff 100%);
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;

                  ${media.md} {
                    font-size: 24px;
                  }

                  ${media.sm} {
                    font-size: 20px;
                  }
                `}
              >
                Bit Portal is not subordinate to one blockchain, which means that the project is
                free from chain dependencies.
              </div>
              <div
                css={css`
                  align-self: stretch;
                  color: #b1a5ff;
                  font-family: Poppins;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 175%; /* 42px */

                  ${media.md} {
                    font-size: 15px;
                  }

                  ${media.sm} {
                    font-size: 12px;
                  }
                `}
              >
                Bit Portal NFT’s price will be pegged to more than one chain’s base cryptocurrency
                (e.g. Ordinals, ETH, and SOL), making it less prone to base crypto’s price drop.
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer
            css={css`
              margin-bottom: 150px;

              ${media.xs} {
                margin-bottom: 88px;
              }
            `}
            justifyContents={'space-between'}
            alignItems={'center'}
          >
            <GridItem
              xl={8}
              lg={8}
              sm={9}
              xs={12}
              css={css`
                position: relative;
              `}
            >
              <img
                src={`${S3_ASSET_HOST_URL}/front/image/section6/absolute6-2.webp`}
                alt="absolute6-2"
                css={css`
                  position: absolute;
                  top: -253px;
                  left: 0;
                  width: 253px;
                  height: 253px;

                  @keyframes wobble {
                    0% {
                      transform: translateY(10px);
                    }
                    100% {
                      transform: translateY(-10px);
                    }
                  }
                  animation: wobble 1s infinite ease-in-out alternate;

                  ${media.md} {
                    top: -138px;
                    width: 138px;
                    height: 138px;
                  }

                  ${media.xs} {
                    display: none;
                  }
                `}
              />
              <div
                css={css`
                  align-self: stretch;
                  font-family: Poppins;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 155%; /* 62px */
                  background: radial-gradient(2080.98% 141.42% at 0% 0%, #d8eaff 0%, #d9c7ff 100%);
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  ${media.md} {
                    font-size: 24px;
                  }
                  ${media.sm} {
                    font-size: 20px;
                  }
                `}
              >
                NFT collection floor price will be less affected by cryptocurrency price drops of a
                specific chain.
              </div>
              <div
                css={css`
                  align-self: stretch;
                  color: #b1a5ff;
                  font-family: Poppins;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 175%; /* 42px */

                  ${media.md} {
                    font-size: 15px;
                  }

                  ${media.sm} {
                    font-size: 12px;
                  }
                `}
              >
                Holders do not have to worry about the future of a specific blockchain. No need to
                ask questions like ‘Will Solana survive this crypto winter?’, ‘Should I purchase
                NFTs from a different chain to hedge my risk?’
              </div>
            </GridItem>
            <GridItem
              css={css`
                text-align: center;
              `}
            >
              <Parallax speed={80}>
                <img
                  src={`${S3_ASSET_HOST_URL}/front/image/section6/absolute6-3.gif`}
                  alt="absolute6-3"
                  css={css`
                    width: fit-content;
                    height: 700px;

                    ${media.md} {
                      width: 46px;
                      height: 243px;
                    }

                    ${media.xs} {
                      display: none;
                    }
                  `}
                />
              </Parallax>
            </GridItem>
          </GridContainer>

          <GridContainer css={css``} justifyContents={'space-between'} alignItems={'center'}>
            <GridItem xl={4}></GridItem>
            <GridItem
              xl={8}
              lg={8}
              sm={9}
              xs={12}
              css={css`
                position: relative;
              `}
            >
              <Parallax
                speed={-10}
                css={css`
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: -161px;
                  width: 161px;
                  height: 167px;

                  ${media.md} {
                    left: -88px;
                    width: 88px;
                    height: 91px;
                  }

                  ${media.xs} {
                    display: none;
                  }
                `}
              >
                <img
                  src={`${S3_ASSET_HOST_URL}/front/image/section6/absolute6-4.webp`}
                  alt="absolute6-4"
                  css={css`
                    width: 100%;
                    height: 100%;
                  `}
                />
              </Parallax>
              <div
                css={css`
                  align-self: stretch;
                  color: #ebdbff;
                  font-family: Poppins;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 155%; /* 62px */

                  ${media.md} {
                    font-size: 24px;
                  }
                  ${media.sm} {
                    font-size: 20px;
                  }
                `}
              >
                Increase in liquidity is expected as there can be an opportunity for arbitrage
                trading.
              </div>
              <div
                css={css`
                  align-self: stretch;
                  color: #b1a5ff;
                  font-family: Poppins;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 175%; /* 42px */

                  ${media.md} {
                    font-size: 15px;
                  }

                  ${media.sm} {
                    font-size: 12px;
                  }
                `}
              >
                The biggest problem with NFTs is the lack of liquidity and trading volume. Bit
                Portal NFTs create a new opportunity for traders to benefit from arbitraging, as the
                floor price of Bit Portal NFTs will vary across chains from time to time, depending
                on the base crypto’s price fluctuation.
              </div>
            </GridItem>
          </GridContainer>
        </BaseContainer>

        {/* TODO Use the wide background have to change it to this code */}
        <Parallax
          translateY={isMobile ? ['-160px', '100px'] : ['-500px', '240px']}
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section6/section6-3.png');
            background-size: 2400px 754px;
            background-position: center bottom;
            background-repeat: repeat-x;
            image-rendering: pixelated;

            ${media.md} {
              background-size: 886px 235px;
            }

            ${media.sm} {
              background-size: 886px 235px;
            }

            ${media.xs} {
              background-size: 740px 247px;
            }
          `}
        />
        <Parallax
          speed={20}
          translateY={isMobile ? ['-160px', '100px'] : ['-500px', '240px']}
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section6/section6-4.png');
            background-position: center bottom;
            background-size: 2400px 758px;
            background-repeat: repeat-x;
            image-rendering: pixelated;

            ${media.md} {
              background-size: 886px 224px;
            }

            ${media.sm} {
              background-size: 886px 224px;
            }

            ${media.xs} {
              background-size: 740px 227px;
            }
          `}
        />
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            background-image: url('${S3_ASSET_HOST_URL}/front/image/section6/section6-5.png');
            background-position: center bottom;
            background-size: 2400px 449px;
            background-repeat: repeat-x;
            image-rendering: pixelated;

            ${media}

            ${media.md} {
              background-position: center bottom;
              background-size: 100% 129px;
            }

            ${media.xs} {
              background-size: 740px 138px;
            }
          `}
        />
      </ParallaxProvider>
    </section>
  );
};

export default Section6;
