import { css, Global } from '@emotion/react';
import normalize from 'emotion-normalize';

// styles
import { theme } from 'styles/theme';

/**
 * @description css variable generate
 * @returns PALETTE에 정의된 객체로 css variables 반환
 */
const generateGlobalVariables = () => {
  let cssVariables = '';
  for (const item of Object.entries(theme.colors)) {
    const [key, value] = item;
    cssVariables += `${key}: ${value};`;
  }

  return css`
    ${cssVariables}
  `;
};

const fonts = css`
  @font-face {
    font-family: 'ThaleahFat';
    src:
      url('/fonts/ThaleahFat.woff2') format('woff2'),
      url('/fonts/ThaleahFat.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;

const style = css`
  ${normalize};
  ${fonts};

  :root {
    ${generateGlobalVariables()};
  }

  html {
    color-scheme: dark;
    overflow-x: hidden;
    overflow-y: auto !important;
    text-rendering: optimizeLegibility;
    image-rendering: pixelated;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    font-family: 'ThaleahFat', Popppins, Tomorrow, sans-serif;
    background: #0d1320;
  }

  html.overflow-y-hidden {
    overflow-y: hidden !important;
  }

  // iOS Safari hack to allow click events on body
  @supports (-webkit-touch-callout: none) {
    body {
      cursor: pointer;
    }
  }

  // Global selectors
  html {
    overflow-y: scroll; // All browsers without overlaying scrollbars
    -webkit-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in iOS
    word-break: normal;
    -moz-tab-size: 4;
    tab-size: 4;

    * {
      box-sizing: border-box;
    }
  }

  *,
  ::before,
  ::after {
    background-repeat: no-repeat; // Set \`background-repeat: no-repeat\` to all elements and pseudo-elements
    box-sizing: inherit;
  }

  ::before,
  ::after {
    text-decoration: inherit; // Inherit text-decoration and vertical-align to ::before and ::after pseudo-elements
    vertical-align: inherit;
  }

  * {
    padding: 0; // Reset \`padding\` and \`margin\` of all elements
    margin: 0;
  }

  // General elements
  hr {
    overflow: visible; // Show the overflow in Edge and IE
    height: 0; // Add the correct box sizing in Firefox
  }

  details,
  main {
    display: block; // Render the \`main\` element consistently in IE
  }

  summary {
    display: list-item; // Add the correct display in all browsers
  }

  small {
    font-size: 80%; // Set font-size to 80% in \`small\` elements
  }

  [hidden] {
    display: none; // Add the correct display in IE
  }

  abbr[title] {
    border-bottom: none; // Remove the bottom border in Chrome 57
    // Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari
    text-decoration: underline dotted;
  }

  a {
    text-decoration: none;
    background-color: transparent; // Remove the gray background on active links in IE 10
  }

  a:active,
  a:hover {
    outline-width: 0; // Remove the outline when hovering in all browsers
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace; // Specify the font family of code elements
  }

  pre {
    font-size: 1em; // Correct the odd \`em\` font sizing in all browsers
  }

  // https://gist.github.com/unruthless/413930
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  // Forms
  input {
    border-radius: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0;
    }
  }

  // Replace pointer cursor in disabled elements
  [disabled] {
    cursor: default;
  }

  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto; // Correct the cursor style of increment and decrement buttons in Chrome
  }

  [type='search'] {
    -webkit-appearance: textfield; // Correct the odd appearance in Chrome and Safari
    outline-offset: -2px; // Correct the outline style in Safari
  }

  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none; // Remove the inner padding in Chrome and Safari on macOS
  }

  textarea {
    overflow: auto; // Internet Explorer 11+
    resize: vertical; // Specify textarea resizability
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit; // Specify font inheritance of form elements
  }

  optgroup {
    font-weight: bold; // Restore the font weight unset by the previous rule
  }

  button {
    overflow: visible; // Address \`overflow\` set to \`hidden\` in IE 8/9/10/11
    color: inherit;
  }

  button,
  select {
    text-transform: none; // Firefox 40+, Internet Explorer 11-
  }

  // Apply cursor pointer to button elements
  button,
  [type='button'],
  [type='reset'],
  [type='submit'],
  [role='button'] {
    cursor: pointer;
    color: inherit;
  }

  // Remove inner padding and border in Firefox 4+
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  // Replace focus style removed in the border reset above
  button:-moz-focusring,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    outline: 1px dotted ButtonText;
  }

  button,
  html [type="button"], // Prevent a WebKit bug where (2) destroys native \`audio\` and \`video\` controls in Android 4
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button; // Correct the inability to style clickable types in iOS
  }

  // Remove the default button styling in all browsers
  button,
  input,
  select,
  textarea {
    background-color: transparent;
    border-style: none;
  }

  // Style select like a standard input
  select {
    -moz-appearance: none; // Firefox 36+
    -webkit-appearance: none; // Chrome 41+
  }

  select::-ms-expand {
    display: none; // Internet Explorer 11+
  }

  select::-ms-value {
    color: currentColor; // Internet Explorer 11+
  }

  legend {
    border: 0; // Correct \`color\` not being inherited in IE 8/9/10/11
    color: inherit; // Correct the color inheritance from \`fieldset\` elements in IE
    display: table; // Correct the text wrapping in Edge and IE
    white-space: normal; // Correct the text wrapping in Edge and IE
    max-width: 100%; // Correct the text wrapping in Edge 18- and IE
  }

  ::-webkit-file-upload-button {
    // Correct the inability to style clickable types in iOS and Safari
    -webkit-appearance: button;
    color: inherit;
    font: inherit; // Change font properties to \`inherit\` in Chrome and Safari
  }

  // Specify media element style
  img {
    border-style: none; // Remove border when inside \`a\` element in IE 8/9/10
  }

  // Add the correct vertical alignment in Chrome, Firefox, and Opera
  progress {
    vertical-align: baseline;
  }

  ul {
    list-style: none;
  }

  //*::-webkit-scrollbar {
  //  width: 12px;
  //  background-color: rgb(96, 96, 96);
  //}
  //
  //*::-webkit-scrollbar-thumb {
  //  border-radius: 12px;
  //  background-color: rgb(96, 96, 96);
  //}
  //
  //*::-webkit-scrollbar-track {
  //  background-color: rgba(40, 40, 40);
  //}
`;

const GlobalStyle = () => <Global styles={style}></Global>;

export default GlobalStyle;
