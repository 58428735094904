import { css } from '@emotion/react';
import { media, theme } from 'styles/theme';
// component
import Button from 'components/common/Button';
import BaseContainer from 'components/common/BaseContainer';
import GridContainer from 'components/common/GridContainer';
import GridItem from 'components/common/GridItem';
// constant
import { S3_ASSET_HOST_URL } from 'constants/url';

const Section11 = () => {
  const handleWhiteLIstButton = () => {
    window.open('https://discord.gg/bitportal');
  };
  return (
    <section
      css={css`
        width: 100%;
        height: auto;
        min-height: 1092px;
        position: relative;

        ${media.xs} {
          min-height: 842px;
        }
      `}
    >
      <BaseContainer>
        <div
          css={css`
            position: relative;
            z-index: 1;
            padding-top: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 50px;

            ${media.xs} {
              padding-top: 80px;
            }
          `}
        >
          <GridContainer justifyContents="center">
            <GridItem
              xl={10}
              lg={12}
              md={8}
              sm={10}
              xs={12}
              css={css`
                display: inline-block;
                color: #640000;
                text-align: center;
                font-family: ThaleahFat;
                font-size: 52px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;

                ${media.md} {
                  font-size: 36px;
                }

                ${media.xs} {
                  font-size: 32px;
                }
              `}
            >
              OWN Bit Portal NFT now
              <br /> and hop on to join our endless journey.
            </GridItem>
          </GridContainer>
          <Button background={'--bt-o-1'} shadow={'orange'} onClick={handleWhiteLIstButton}>
            Get WhiteListed
          </Button>
        </div>

        {/* Background Image */}
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section11/section11-1.webp');
            background-size: 1920px 100%;
            background-position: left bottom;
            background-repeat: repeat-x;

            @media (min-width: ${theme.breakPoints.xl}px) {
              background-image: url('${S3_ASSET_HOST_URL}/front/image/section11/section11-1.webp'),
                url('${S3_ASSET_HOST_URL}/front/image/section11/section11-1.webp');
              background-position:
                0 bottom,
                1920px bottom;
              background-repeat: no-repeat;
              background-size:
                1920px 1092px,
                1920px 1092px;
            }
          `}
        />

        <img
          src={`${S3_ASSET_HOST_URL}/front/image/section11/section11-2.gif`}
          alt=""
          css={css`
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 333px;
            max-width: 344px;
            height: auto;

            ${media.xs} {
              bottom: 250px;
            }
          `}
        />
        <div
          css={css`
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 280px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            max-width: 700px;

            ${media.xs} {
              bottom: 250px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section11/absolute11-1.gif`}
            alt=""
            css={css`
              width: 98px;
              height: 124px;
              max-width: 344px;

              ${media.xs} {
                width: 68px;
                height: 90px;
              }
            `}
          />
          <div>
            <img
              src={`${S3_ASSET_HOST_URL}/front/image/section11/absolute11-2.gif`}
              alt=""
              css={css`
                width: 68px;
                height: 86px;
                max-width: 344px;

                ${media.xs} {
                  width: 40px;
                  height: 50px;
                }
              `}
            />
            <img
              src={`${S3_ASSET_HOST_URL}/front/image/section11/absolute11-2.gif`}
              alt=""
              css={css`
                width: 68px;
                height: 86px;
                max-width: 344px;

                ${media.xs} {
                  width: 40px;
                  height: 50px;
                }
              `}
            />
          </div>
        </div>
        {/* Background Image */}
      </BaseContainer>
    </section>
  );
};

export default Section11;
