import React from 'react';
import Head, { TitleType } from 'components/common/Head';
import Section1 from 'components/home/Section1';
import Section2 from 'components/home/Section2';
import Section3 from 'components/home/Section3';
import Section4 from 'components/home/Section4';
import Section5 from 'components/home/Section5';
import Section6 from 'components/home/Section6';
import Section7 from 'components/home/Section7';
import Section8 from 'components/home/Section8';
import Section9 from 'components/home/Section9';
import Section10 from 'components/home/Section10';
import Section11 from 'components/home/Section11';

const Home = () => {
  return (
    <>
      <Head title={TitleType.HOME} />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      {/* TODO FAQ 콘텐츠 준비 완료 시 다시 오픈 ( 2023/11/16 ) */}
      {/*<Section9 />*/}
      <Section10 />
      <Section11 />
    </>
  );
};

export default Home;
