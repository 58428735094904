import { css } from '@emotion/react';
import React from 'react';
import { media } from 'styles/theme';
import BaseContainer from '../common/BaseContainer';
import { S3_ASSET_HOST_URL } from 'constants/url';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { detectMobile } from '../../utils/common/detectEnviorment';

const Section1 = () => {
  const isMobile = detectMobile();

  return (
    <section
      css={css`
        background: linear-gradient(180deg, #040538 0%, #6a2693 100%);
        position: relative;
        height: 1400px;
        overflow: hidden;

        ${media.md} {
          height: 1127px;
        }

        ${media.sm} {
          height: 966px;
        }

        ${media.xs} {
          height: 800px;
        }
      `}
    >
      <BaseContainer>
        <article
          css={css`
            width: 100%;
            height: auto;
            padding-top: 100px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            ${media.md} {
              flex-direction: column;
              justify-content: center;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section1/main1-1.gif`}
            alt=""
            css={css`
              z-index: 1;
              width: auto;
              height: auto;

              ${media.lg} {
                width: 606px;
                height: 454px;
              }

              ${media.sm} {
                width: 482px;
                height: 363px;
              }

              ${media.xs} {
                width: 326px;
                height: 244px;
              }
            `}
          />

          <div
            css={css`
              z-index: 2;
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              transform: translateY(0);
              ${media.md} {
                transform: translateY(-80px);
                text-align: center;
                align-items: center;
              }

              ${media.sm} {
                transform: translateY(-50px);
              }
            `}
          >
            <img
              src={`${S3_ASSET_HOST_URL}/front/image/section1/main1-3.webp`}
              alt=""
              css={css`
                position: absolute;
                top: -30px;
                right: -20px;
                width: 108px;
                height: 136px;

                ${media.md} {
                  display: none;
                }
              `}
            />
            <img
              src={`${S3_ASSET_HOST_URL}/front/image/section1/section1-logo.gif`}
              css={css`
                width: 246px;
                height: auto;
                position: relative;
                margin-bottom: 30px;
                transform: translate(0, 50px);

                ${media.sm} {
                  width: 210px;
                  height: 122px;
                  transform: translate(0, 30px);
                  margin-bottom: 18px;
                }

                ${media.xs} {
                  width: 174px;
                  height: 102px;
                }
              `}
            />
            <h2
              css={css`
                color: #ece28b;
                text-align: right;
                text-shadow: 0px 3px 0px #7769f9;
                font-family: ThaleahFat;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 95%; /* 38px */

                ${media.md} {
                  text-align: center;
                  font-size: 32px;
                }
                ${media.sm} {
                  font-size: 24px;
                }

                ${media.xs} {
                  font-size: 20px;
                }
              `}
            >
              <span>MEET BIT PORTAL</span>

              <br />
              <span
                css={css`
                  white-space: nowrap;
                  width: 100%;
                `}
              >
                THE MULTI CHAIN NFT UNIVERSE
              </span>
            </h2>
          </div>
        </article>
      </BaseContainer>
      {/* Background images */}
      <ParallaxProvider>
        <Parallax
          speed={-230}
          css={css`
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 0;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section1/section1-1.webp');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          `}
        />
        <Parallax
          speed={-140}
          css={css`
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section1/section1-2.webp');
            background-size: 1669px 553px;
            background-position: center 20%;
            background-repeat: no-repeat;

            ${media.md} {
              background-size: 1024px 353px;
            }
          `}
        />
        <Parallax
          speed={-240}
          css={css`
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section1/section1-3.webp');
            background-size: 1862px 539px;
            background-position: center 10%;
            background-repeat: no-repeat;
            background-color: transparent;
          `}
        />
        <Parallax
          // translateY={isMobile ? ['-200px', '100px'] : ['-200px', '280px']}
          speed={isMobile ? -10 : -20}
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section1/section1-4.png');
            background-size: 1920px 847px;
            background-position: center bottom;
            background-repeat: repeat-x;
            image-rendering: pixelated;

            ${media.lg} {
              background-size: 1920px 847px;
            }

            ${media.md} {
              background-size: 1048px 447px;
            }

            ${media.sm} {
              background-size: 740px 326px;
            }

            ${media.xs} {
              background-size: 606px 277px;
            }
          `}
        />
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section1/section1-5.png');
            background-size: 1920px 441px;
            background-position: center bottom;
            background-repeat: repeat-x;
            image-rendering: pixelated;

            ${media.md} {
              background-size: 1048px 241px;
            }

            ${media.sm} {
              background-size: 740px 170px;
            }

            ${media.xs} {
              background-size: 606px 139px;
            }
          `}
        />
        <div
          css={css`
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1;
            bottom: -200px;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section1/section1-6.webp');
            background-size: 2476px 400px;
            background-position: center bottom;
            background-repeat: repeat-x;
            background-color: transparent;
            image-rendering: pixelated;

            ${media.lg} {
              background-size: 1299px 210px;
              bottom: -100px;
            }
          `}
        />
        <Parallax
          // translateY={isMobile ? ['-200px', '100px'] : ['-200px', '280px']}
          speed={isMobile ? -10 : -20}
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section1/section1-7.png');
            background-size: 1920px 816px;
            background-position: center bottom;
            background-repeat: repeat-x;
            image-rendering: pixelated;

            ${media.lg} {
              background-size: 1920px 819px;
            }
            ${media.md} {
              background-size: 1048px 447px;
            }

            ${media.sm} {
              background-size: 740px 315px;
            }

            ${media.xs} {
              background-size: 606px 278px;
            }
          `}
        />
        <img
          src={`${S3_ASSET_HOST_URL}/front/image/section1/main1-2.webp`}
          alt="main1-2"
          css={css`
            position: absolute;
            left: 45%;
            transform: translateX(-45%);
            bottom: 270px;
            z-index: 1;
            width: auto;
            height: auto;

            ${media.lg} {
              left: 35%;
              transform: translateX(-35%);
              width: 478px;
              height: 456px;
              bottom: 280px;
            }

            ${media.md} {
              left: 42%;
              transform: translateX(-42%);
              width: 253px;
              height: 242px;
              bottom: 150px;
            }

            ${media.sm} {
              width: 195px;
              height: 186px;
            }

            ${media.xs} {
              bottom: 90px;
              width: 143px;
              height: 137px;
            }
          `}
        />
      </ParallaxProvider>
      {/* Background images */}
    </section>
  );
};

export default Section1;
