import React, { PropsWithChildren } from 'react';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { media } from 'styles/theme';

const BaseContainer = ({ children, style }: PropsWithChildren<{ style?: SerializedStyles }>) => {
  return <Container css={style}>{children}</Container>;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 24px;

  ${media.md} {
    padding: 0 16px;
  }
`;

export default BaseContainer;
