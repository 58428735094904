import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { useLayoutEffect, useState } from 'react';
import GridItem from '../common/GridItem';
import GridContainer from '../common/GridContainer';
import BaseContainer from '../common/BaseContainer';
import { media } from '../../styles/theme';
import { S3_ASSET_HOST_URL } from 'constants/url';

const SECTIONS = [
  {
    sectionNumber: 1,
    description:
      'When cryptocurrency came on the scene, people went all in, trading and investing like crazy. Bitcoin, Ethereum, altcoins, NFTs—you name it, the prices shot up like rockets. It was like a golden era for making money, easier than ever before.',
  },
  {
    sectionNumber: 2,
    description:
      'But then, boom! The market crashed, crushing the dreams of these traders. Their millionaire dreams were shattered, leaving them feeling totally empty. Lots of them gave up and now their lost souls wander the universe.',
  },
  {
    sectionNumber: 3,
    description:
      'After floating the universe for ages, the lost souls finally landed on the Planet Ordinals, where primitive Yokais hung out, primarily engaging in bug-hunting and aimless wandering.',
  },
  {
    sectionNumber: 4,
    description:
      "One day, these Yokais stumble upon a bunch of weird souls raining down from the sky. The Yokais were totally blown away by what they saw and couldn't look away. Out of nowhere, the souls came plummeting down and whoosh! They just went right into those Yokais.",
  },
  {
    sectionNumber: 5,
    description:
      'The Yokais felt this crazy energy exploding from their hearts. Next thing you know, their bodies morphed into humans, and guess what? They now had this burning desire to go on a treasure hunt for valuable minerals.',
  },
  {
    sectionNumber: 6,
    description:
      "Yokais leveled up into a super advanced civilization. They got clever and started searching for a new planet with even more valuable minerals. Boom, they found one with potential and named it Solana. Today's a big day because it's the start of planet-hopping adventures.",
  },
  {
    sectionNumber: 7,
    description:
      'Their mission? To find and settle on as many planets as they can, securing eternal prosperity for future generations. They call it the "Bit Portal" and are embarking on a grand journey.',
  },
];

const SectionMockView = ({
  sectionNumber,
  onChangeSectionNumber,
}: {
  sectionNumber: number;
  onChangeSectionNumber: (viewNumber: number) => void;
}) => {
  return (
    <>
      <motion.div
        onViewportEnter={() => {
          onChangeSectionNumber(sectionNumber);
        }}
        css={css`
          width: 100%;
          height: 200vh;
          color: white;
          font-size: 5rem;
        `}
      ></motion.div>
    </>
  );
};

const videoPath = `${S3_ASSET_HOST_URL}/front/image/section4`;

const Section4 = () => {
  const [sectionNumber, setSectionNumber] = useState(SECTIONS[0].sectionNumber);
  const [description, setDescription] = useState(SECTIONS[0].description);

  useLayoutEffect(() => {
    const preloadVideo = () => {
      SECTIONS.forEach(section => {
        const video = new Image();
        video.src = `${videoPath}/section4-${section.sectionNumber}.mp4`;
      });
    };
    preloadVideo();
  }, []);

  const onChangeSectionNumber = (viewNumber: number) => {
    if (viewNumber === sectionNumber) {
      return;
    }

    const section = SECTIONS.filter(i => i.sectionNumber === viewNumber)[0];

    setSectionNumber(section.sectionNumber);
    setDescription(section.description);
  };

  return (
    <section
      css={css`
        position: relative;
        width: 100%;
        background: #0d1320;
      `}
    >
      <BaseContainer
        style={css`
          position: sticky;
          top: 0;
          width: 100%;
          height: 100vh;
        `}
      >
        <GridContainer
          key={sectionNumber}
          css={css`
            margin: 0 auto;
            background: #0d1320;
            text-align: center;
          `}
        >
          <GridItem
            xl={8}
            lg={8}
            md={8}
            xs={10}
            css={css`
              width: 100%;
              height: auto;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          >
            <video
              src={`${videoPath}/section4-${sectionNumber}.mp4`}
              muted
              autoPlay
              loop
              playsInline
              preload="metadata"
              controls={false}
              css={css`
                width: 100%;
                display: flex;
                margin-bottom: 40px;

                min-height: 477px;

                ${media.lg} {
                  min-height: 390px;
                }

                ${media.md} {
                  min-height: 297px;
                }

                ${media.sm} {
                  min-height: 211px;
                }

                ${media.xs} {
                  min-height: 185px;
                }
              `}
            />
            <motion.span
              css={css`
                --n: 300;

                //max-width: 791px;
                color: #0000;
                background:
                  linear-gradient(-90deg, #fff 5px, #0000 0) 10px 0,
                  linear-gradient(#fff 0 0) 0 0;
                background-size: calc(var(--n) * 1ch) 200%;
                -webkit-background-clip: padding-box, text;
                background-clip: padding-box, text;
                background-repeat: no-repeat;

                text-align: center;
                margin: 0 auto;
                font-family: ThaleahFat;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                @keyframes t {
                  from {
                    background-size: 0 200%;
                  }
                }
                @keyframes b {
                  50% {
                    background-position:
                      0 -100%,
                      0 0;
                  }
                }

                animation: t calc(var(--n) * 15ms) steps(var(--n)) forwards;

                ${media.md} {
                  font-size: 24px;
                }
              `}
              initial={{ opacity: 0, x: 30 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { bounce: 0, bounceDamping: 0, bounceStiffness: 0, duration: 0.2 },
              }}
            >
              {description}
            </motion.span>
          </GridItem>
        </GridContainer>
      </BaseContainer>
      {SECTIONS.map((section, index) => {
        return (
          <SectionMockView
            key={section.sectionNumber}
            sectionNumber={section.sectionNumber}
            onChangeSectionNumber={onChangeSectionNumber}
          />
        );
      })}
    </section>
  );
};

export default Section4;
