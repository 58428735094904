import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { media, theme } from 'styles/theme';
import GridContainer from './GridContainer';
import GridItem from './GridItem';
import { S3_ASSET_HOST_URL } from 'constants/url';

const FOOTER_CONTENTS = [
  // {
  //   title: 'Switchverse',
  //   contents: [{ name: 'Opensea', link: 'https://opensea.io/kr' }],
  // }, TODO Opensae 준비되면 오픈
  {
    title: 'Social',
    contents: [
      {
        name: 'Discord',
        link: 'https://discord.gg/bitportal',
        target: '_blank',
      },
      {
        name: 'Twitter',
        link: 'https://x.com/bitportal_nft',
        target: '_blank',
      },
      // {
      //   name: 'Medium',
      //   link: '/',
      // }, TODO Medium 준비되면 오픈
    ],
  },
  {
    title: 'Resource',
    contents: [
      {
        name: 'Whitepaper',
        link: 'https://bitportal.gitbook.io/whitepaper',
        target: '_blank',
      },
      {
        name: 'Terms of Service',
        link: '/terms',
        target: '_self',
      },
      {
        name: 'Privacy Policy',
        link: '/privacy',
        target: '_self',
      },
      {
        name: 'Contact Us',
        link: 'mailto:support@bitportal.xyz',
        target: '_blank',
      },
    ],
  },
];

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLayout>
        <Logo src={`${S3_ASSET_HOST_URL}/front/image/footer/footer-logo.webp`} alt="footer-logo" />

        <GridContainer></GridContainer>

        <ContentsBox>
          {FOOTER_CONTENTS.map((parent, parentIndex) => {
            return (
              <GridItem key={parentIndex} xl={2} lg={2} md={2} sm={6} xs={6}>
                <dl>
                  <dt>{parent.title}</dt>
                  {parent.contents.map((child, childIndex) => {
                    return (
                      <Link key={childIndex} to={child.link} target={child.target}>
                        <dd>{child.name}</dd>
                      </Link>
                    );
                  })}
                </dl>
              </GridItem>
            );
          })}
        </ContentsBox>
        <Line />
        <span
          css={css`
            position: relative;
            z-index: 2;
            color: #baa1ff;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 175%; /* 26.25px */
          `}
        >
          © 2023 Fungeeble Ltd. All rights reserved.
        </span>
      </FooterLayout>

      {/* Background Images */}
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-image: url('${S3_ASSET_HOST_URL}/front/image/footer/footer1.webp'),
            url('${S3_ASSET_HOST_URL}/front/image/footer/footer2.webp'),
            url('${S3_ASSET_HOST_URL}/front/image/footer/footer3.webp');
          background-position:
            left bottom,
            right bottom,
            center bottom;
          background-size:
            auto auto,
            auto auto,
            100% 20px;

          ${media.sm} {
            background-image: url('${S3_ASSET_HOST_URL}/front/image/footer/footer2.webp'),
              url('${S3_ASSET_HOST_URL}/front/image/footer/footer3.webp');
            background-position:
              right bottom,
              center bottom;
            background-size:
              auto auto,
              100% auto;
          }
        `}
      />
      <img
        src={`${S3_ASSET_HOST_URL}/front/image/footer/footer5.webp`}
        alt="planet-image"
        css={css`
          position: absolute;
          bottom: 0;
          right: -100px;
          z-index: 0;
          width: auto;
          height: auto;

          @media (max-width: ${theme.breakPoints.md}px) {
            display: none;
          }
        `}
      />
      <img
        src={`${S3_ASSET_HOST_URL}/front/image/footer/footer4.gif`}
        alt=""
        css={css`
          position: absolute;
          top: 0;
          right: 10%;
          z-index: 0;
          width: 63px;
          height: auto;
          @media (max-width: ${theme.breakPoints.md}px) {
            display: none;
          }
        `}
      />
      {/* Background Images */}
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 480px; // TODO mobile to 307px
  padding: 40px 16px;
  background: #2d0d61;

  ${media.sm} {
    height: 340px;
    padding: 0px 8px;
  }
`;

const FooterLayout = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  //padding-left: 320px;

  ${media.lg} {
    max-width: 1100px;
  }

  ${media.md} {
    max-width: 668px;
  }

  ${media.sm} {
    max-width: 434px;
  }

  ${media.xs} {
    max-width: 328px;
  }
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 64px;

  ${media.lg} {
    margin-bottom: 24px;
  }
`;

const ContentsBox = styled(GridContainer)`
  margin-bottom: 60px;
  position: relative;
  z-index: 3;
  dl {
    dt {
      margin-bottom: 8px;
      color: #fff;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 36px */

      ${media.md} {
        font-size: 20px;
      }
    }

    dd {
      color: rgba(255, 255, 255, 0.76);
      margin: 8px 0;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 175%; /* 26.25px */
      transition: color 0.21s;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }

      ${media.sm} {
        font-size: 12px;
      }

      ${media.xs} {
        justify-content: space-between !important;
      }
    }
  }
`;

const Line = styled.hr`
  width: 100%;
  margin-bottom: 32px;
  height: 1px;
  border: none;
  background-color: #431a84;

  ${media.md} {
    display: none;
  }
`;
