import { forwardRef, HTMLAttributes, memo, PropsWithChildren, Ref } from 'react';
import styled from '@emotion/styled';

const GridContainer = (
  {
    justifyContents,
    alignItems,
    gap,
    direction,
    children,
    ...rest
  }: GridProps & HTMLAttributes<HTMLDivElement> & PropsWithChildren,
  ref?: Ref<HTMLDivElement>,
) => {
  return (
    <StyledGridContainer
      justifyContents={justifyContents}
      alignItems={alignItems}
      gap={gap}
      direction={direction}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledGridContainer>
  );
};

type JustifyType =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'stretch'
  | 'start'
  | 'end';
type AlignType = 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'start' | 'end';
type DirectionType = 'row' | 'column' | 'row-reverse' | 'column-reverse';
const StyledGridContainer = styled.div<GridProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContents }) => justifyContents || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  flex-direction: ${({ direction }) => direction || 'row'};
  gap: ${({ gap }) => gap || 0}px;
  flex-wrap: wrap;
`;

interface GridProps {
  justifyContents?: JustifyType;
  alignItems?: AlignType;
  gap?: number;
  direction?: DirectionType;
}

export default memo(forwardRef(GridContainer));
