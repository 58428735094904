export const colors = {
  '--bt-y-1': 'radial-gradient(807.81% 141.53% at 0% 0%, #A1FFCB 0%, #E5FF4E 80.61%)',
  '--bt-y-2': 'radial-gradient(807.81% 141.53% at 0% 0%, #B5FFD6 0%, #EBFF7A 80.61%)',
  '--bt-y-shade': 'rgba(139, 163, 0, 0.56)',
  '--bt-y-t-1': 'rgba(250, 251, 252, 1)',
  '--bt-y-t-2': 'rgba(0, 11, 112, 1)',
  '--bt-o-1': ' linear-gradient(276deg, #F06158 36.02%, #FFA450 100%)',
  '--bt-o-2': 'radial-gradient(436.71% 141.42% at 0% 0%, #FFCC80 0%, #F67971 59.34%)',
  '--bt-o-shade': 'rgba(195, 73, 65, 0.56)',
  '--bt-wht': '#fff',
  '--bt-p-1': 'linear-gradient(277deg, #9B58F0 28.45%, #C958F0 99.87%)',
  '--bt-p-2': 'radial-gradient(436.71% 141.42% at 0% 0%, #DE7EFF 0%, #9A81FF 36.44%)',
  '--bt-p-shade': 'rgba(54, 0, 124, 0.56)',
} as const;

export type ColorsKey = keyof typeof colors;

export const mediaQuery = (width: (typeof breakPoints)[keyof typeof breakPoints]): string => {
  if (width === 1920) {
    return `@media (min-width: ${width + 1}px)`;
  } else {
    return `@media (max-width: ${width}px) `;
  }
};

export const breakPoints = {
  xl: 1920,
  lg: 1100,
  md: 900,
  sm: 600,
  xs: 450,
} as const;

export const media = {
  xl: mediaQuery(breakPoints.xl),
  lg: mediaQuery(breakPoints.lg),
  md: mediaQuery(breakPoints.md),
  sm: mediaQuery(breakPoints.sm),
  xs: mediaQuery(breakPoints.xs),
};

export const theme = {
  colors,
  breakPoints,
};
