import { css } from '@emotion/react';
import { useEffect, useRef } from 'react';
import { media } from 'styles/theme';
import { S3_ASSET_HOST_URL } from 'constants/url';

const BAND_TEXT_QTY = Array.from({ length: 20 });
const Section8 = () => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const xCount = useRef<number>(0);
  const handleIntiniteAnimation = () => {
    if (sliderRef.current) {
      const { scrollWidth } = sliderRef.current;

      if (xCount.current > scrollWidth / 4) {
        sliderRef.current.style.transform = `translate3d(0, 0, 0)`;
        xCount.current = 0;
      }

      sliderRef.current.style.transform = `translate3d(${xCount.current}px, 0, 0)`;
      xCount.current += 1;
    }

    requestAnimationFrame(handleIntiniteAnimation);
  };

  useEffect(() => {
    handleIntiniteAnimation();
  }, []);

  return (
    <section
      css={css`
        position: relative;
        max-width: 100%;
        overflow: hidden;
        margin-top: 100px;
      `}
    >
      <article
        css={css`
          width: 100%;
          height: 100%;
          padding-top: 200px;
          background-image: url('${S3_ASSET_HOST_URL}/front/image/section8/section8-1.webp');
          background-size: cover;
          background-position: center top;
          z-index: 4;

          ${media.sm} {
            padding-top: 100px;
          }
        `}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section8/section8-3.gif`}
            alt=""
            css={css`
              width: 200px;
              height: 130px;

              ${media.lg} {
                position: absolute;
                top: 0;
                left: 16px;
              }

              ${media.md} {
                width: 132px;
                height: 87px;
              }

              ${media.sm} {
                width: 93px;
                height: 61px;
              }

              ${media.xs} {
                width: 81px;
                height: 54px;
              }
            `}
          />
          <h1
            css={css`
              max-width: 870px;
              text-align: center;
              font-family: Poppins;
              font-size: 92px;
              font-style: normal;
              font-weight: 700;
              line-height: 108%; /* 99.36px */
              background: linear-gradient(97deg, #87ff9a 0.13%, #6cd3ff 50.59%, #ab69ff 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              ${media.lg} {
                font-size: 70px;
              }

              ${media.sm} {
                font-size: 52px;
              }

              ${media.xs} {
                font-size: 36px;
              }
            `}
          >
            Meet <br />
            Bit Portal Frens
          </h1>
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section8/section8-4.gif`}
            alt=""
            css={css`
              width: 200px;
              height: 130px;

              ${media.lg} {
                position: absolute;
                top: 0;
                right: 16px;
              }

              ${media.md} {
                width: 132px;
                height: 87px;
              }

              ${media.sm} {
                width: 93px;
                height: 61px;
              }

              ${media.xs} {
                width: 81px;
                height: 54px;
              }
            `}
          />
        </div>

        <div
          ref={sliderRef}
          css={css`
            display: flex;
            position: relative;
            left: -1800px; // 이미지 3개 이어붙인 width만큼
            bottom: 0;
            height: 284px;

            ${media.xs} {
              height: 255px;
            }
          `}
        >
          <img src={`${S3_ASSET_HOST_URL}/front/image/section8/section8-2.webp`} alt="" />
          <img src={`${S3_ASSET_HOST_URL}/front/image/section8/section8-2.webp`} alt="" />
          <img src={`${S3_ASSET_HOST_URL}/front/image/section8/section8-2.webp`} alt="" />
          <img src={`${S3_ASSET_HOST_URL}/front/image/section8/section8-2.webp`} alt="" />
        </div>
        <div
          css={css`
            display: flex;
            width: 100%;
            padding: 20px 10px;
            justify-content: center;
            align-items: flex-start;
            gap: 28px;
            background: #5717a9;

            ${media.md} {
              padding: 16px 10px;
              gap: 24px;
            }

            ${media.xs} {
              gap: 16px;
            }
          `}
        >
          {BAND_TEXT_QTY.map((_, index) => {
            return (
              <span
                key={index}
                css={css`
                  display: inline-block;
                  color: #874fdf;
                  font-family: ThaleahFat;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  white-space: nowrap;

                  ${media.md} {
                    font-size: 24px;
                  }

                  ${media.xs} {
                    font-size: 20px;
                  }
                `}
              >
                Bit Portal
              </span>
            );
          })}
        </div>
      </article>
    </section>
  );
};

export default Section8;
