import { HelmetProvider, Helmet } from 'react-helmet-async';

const Head = ({ title = TitleType.HOME }: HeadProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta property="title" content={title} />
        <meta
          property="description"
          content="“Bit Portal is a metaverse comprised of NFT characters that exists on both Bitcoin and Ethereum chain with in-house developed NFT portal technology.”"
        />
        <meta property="og:title" content={title} key="title" />
        <meta
          property="og:description"
          content="Bit Portal is a metaverse comprised of NFT characters that exists on both Bitcoin and Ethereum chain with in-house developed NFT portal technology."
          key="description"
        />
      </Helmet>
    </HelmetProvider>
  );
};

export enum TitleType {
  HOME = 'Bit Portal | Travel across different chain worlds with Bit Portal NFT',
  TOS = 'Terms of Service | Bit Portal',
  PP = 'Privacy Policy | Bit Portal',
}

interface HeadProps {
  title: TitleType;
}

export default Head;
