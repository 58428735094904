import { css } from '@emotion/react';
import GridContainer from 'components/common/GridContainer';
import GridItem from 'components/common/GridItem';
import Button from 'components/common/Button';
import React from 'react';
import { media } from 'styles/theme';
import styled from '@emotion/styled';
import BaseContainer from '../common/BaseContainer';
import { S3_ASSET_HOST_URL } from 'constants/url';
import { Parallax, ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import ParallaxBox from '../common/ParallaxBox';

const Section2 = () => {
  return (
    <ParallaxProvider>
      <section
        css={css`
          position: relative;
          width: 100%;
          min-height: 1145px;
          overflow: hidden;
          background: #151371;

          ${media.lg} {
            min-height: 1100px;
          }

          ${media.md} {
            min-height: 764px;
          }

          ${media.sm} {
            min-height: 686px;
          }

          ${media.xs} {
            min-height: 800px;
          }
        `}
      >
        <BaseContainer
          style={css`
            position: relative;
          `}
        >
          <GridContainer justifyContents={'center'}>
            <GridItem
              xl={8}
              lg={8}
              md={8}
              sm={10}
              xs={12}
              css={css`
                margin: 127px 0 52px;
              `}
            >
              <img
                src={`${S3_ASSET_HOST_URL}/front/image/section2/section2-1.gif`}
                alt=""
                css={css`
                  width: 100%;
                `}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContents={'center'}>
            <GridItem xl={8} lg={9} md={8} xs={11}>
              <span
                css={css`
                  position: relative;
                  z-index: 2;
                  display: inline-block;
                  width: 100%;
                  margin-bottom: 28px;
                  text-align: center;
                  font-family: Tomorrow;
                  font-size: 64px;
                  font-style: italic;
                  font-weight: 700;
                  line-height: 115%; /* 73.6px */
                  background: radial-gradient(
                    1134.84% 120.84% at 4.01% 7.88%,
                    #9effd0 0%,
                    #e5ff4e 100%
                  );
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;

                  ${media.md} {
                    font-size: 32px;
                  }
                `}
              >
                Travel across different chain worlds with Bit Portal NFT
              </span>
            </GridItem>
          </GridContainer>
          <GridContainer
            justifyContents={'center'}
            css={css`
              margin-bottom: 40px;
            `}
          >
            <GridItem xl={8} lg={9} md={8} xs={11}>
              <span
                css={css`
                  display: inline-block;
                  width: 100%;
                  color: #d8e3ff;
                  text-align: center;
                  text-shadow: 0px 2px 0px #311cb2;
                  font-family: Poppins, sans-serif;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 170%; /* 40.8px */
                  letter-spacing: 0.24px;

                  ${media.md} {
                    font-size: 15px;
                  }
                `}
              >
                Bit Portal is a collection of PFP NFTs that live on Ordinals, Ethereum, and Solana
                blockchains using the project’s in-house developed interoperable NFT switch
                technology.
              </span>
            </GridItem>
          </GridContainer>
          <GridContainer justifyContents={'center'}>
            <Button disabled>VIEW COLLECTION</Button>
          </GridContainer>
          <Parallax
            speed={50}
            css={css`
              position: absolute;
              top: 30%;
              left: 20px;
              width: 126px;
              height: 398px;

              ${media.lg} {
                width: 102px;
                height: 322px;
              }

              ${media.md} {
                width: 84px;
                height: 264px;
              }

              ${media.sm} {
                top: 226px;
                width: 65px;
                height: 206px;
              }

              ${media.xs} {
                display: none;
              }
            `}
          >
            <img
              src={`${S3_ASSET_HOST_URL}/front/image/section2/section2-2.gif`}
              alt=""
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </Parallax>
          <Parallax
            speed={10}
            css={css`
              position: absolute;
              bottom: 10%;
              right: 20px;
              width: 108px;
              height: 343px;

              ${media.lg} {
                bottom: 124px;
                width: 101px;
                height: 322px;
              }
              ${media.md} {
                bottom: 127px;
                width: 74px;
                height: 236px;
              }
              ${media.sm} {
                bottom: 34px;
                width: 62px;
                height: 195px;
              }

              ${media.xs} {
                display: none;
              }
            `}
          >
            <img
              src={`${S3_ASSET_HOST_URL}/front/image/section2/section2-3.gif`}
              alt=""
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </Parallax>
        </BaseContainer>
      </section>
    </ParallaxProvider>
  );
};

export default Section2;

const AbsoluteImage = styled.img`
  position: absolute;
  top: 10%;
  left: 10%;
  width: 126px;
  height: 398px;
`;
