import { css } from '@emotion/react';
import React from 'react';
import { media } from '../../styles/theme';
import { S3_ASSET_HOST_URL } from 'constants/url';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import styled from '@emotion/styled';

const Section5 = () => {
  return (
    <section
      css={css`
        background: linear-gradient(0, #2c1856 0%, #0d1320 100%), fixed;
        position: relative;
        height: 4660px;
        overflow: hidden;
        ${media.md} {
          height: 3260px;
        }

        ${media.sm} {
          height: 3093px;
        }

        ${media.xs} {
          height: 3028px;
        }
      `}
    >
      <ParallaxProvider>
        <BGWrapper>
          <BG speed={-150} order={1} height={443} />
          <BG speed={-120} order={2} height={743} />
          <BG speed={-180} order={3} height={1330} />
          <BG speed={-70} order={4} height={1589} />
          <BG speed={-140} order={5} height={634} />
        </BGWrapper>

        {/* 1 */}
        <img
          src={`${S3_ASSET_HOST_URL}/front/image/section5/star.gif`}
          alt="star"
          css={css`
            position: absolute;
            width: 275px;
            height: 275px;
            top: 8px;
            left: 677px;
          `}
        />
        {/* 2 */}
        <img
          src={`${S3_ASSET_HOST_URL}/front/image/section5/star.gif`}
          alt="star"
          css={css`
            position: absolute;
            width: 230px;
            height: 230px;
            top: 270px;
            right: 134px;
          `}
        />
        {/* 3 */}
        <img
          src={`${S3_ASSET_HOST_URL}/front/image/section5/star.gif`}
          alt="star"
          css={css`
            position: absolute;
            width: 306px;
            height: 306px;
            top: 1652px;
            left: 161px;
          `}
        />
        {/* 4 */}
        <img
          src={`${S3_ASSET_HOST_URL}/front/image/section5/star.gif`}
          alt="star"
          css={css`
            position: absolute;
            width: 279px;
            height: 279px;
            top: 2754px;
            left: 660px;
          `}
        />
        {/* 5 */}
        <img
          src={`${S3_ASSET_HOST_URL}/front/image/section5/star.gif`}
          alt="star"
          css={css`
            position: absolute;
            width: 279px;
            height: 279px;
            bottom: 559px;
            right: 561px;
          `}
        />

        <Parallax
          speed={-20}
          css={css`
            width: 676px;
            height: 650px;
            position: absolute;
            top: 187px;
            left: 276px;
            z-index: 0;
            image-rendering: pixelated;

            ${media.md} {
              top: 392px;
              left: 131px;
              width: 320px;
              height: 308px;
            }

            ${media.sm} {
              left: 47px;
              top: 293px;
            }

            ${media.xs} {
              top: 275px;
              left: 24px;
              width: 256px;
              height: 246px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/planet-1.png`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>
        <Parallax
          speed={-10}
          css={css`
            width: 1085px;
            height: 593px;
            position: absolute;
            left: -200px;
            top: 534px;
            z-index: 0;
            image-rendering: pixelated;

            ${media.md} {
              width: 650px;
              height: 293px;
            }

            ${media.sm} {
              top: 498px;
              left: -142px;
              width: 503px;
              height: 243px;
            }

            ${media.xs} {
              top: 440px;
              left: -110px;
              width: 428px;
              height: 190px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/cloud-1.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          ></img>
        </Parallax>

        <Parallax
          speed={20}
          css={css`
            width: 523px;
            height: 520px;
            position: absolute;
            top: 1141px;
            right: 282px;
            z-index: 1;
            image-rendering: pixelated;

            ${media.lg} {
              width: 315px;
              height: 278px;
              top: 1383px;
              right: 99px;
            }

            ${media.md} {
              top: 884px;
              width: 340px;
              height: 338px;
            }

            ${media.sm} {
              top: 860px;
              width: 280px;
              height: 278px;
            }

            ${media.xs} {
              top: 928px;
              right: 24px;
              width: 226px;
              height: 224px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/planet-2.png`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>
        <Parallax
          speed={10}
          css={css`
            width: 605px;
            height: 157px;
            position: absolute;
            top: 1512px;
            right: -35px;
            z-index: 0;

            ${media.lg} {
              right: -320px;
            }

            ${media.md} {
              top: 1084px;
            }

            ${media.sm} {
              top: 1048px;
              right: -30px;
              width: 323px;
              height: 83px;
            }

            ${media.xs} {
              top: 1068px;
              right: -140px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/cloud-2-1.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>
        <Parallax
          speed={25}
          css={css`
            width: 321px;
            height: 210px;
            position: absolute;
            top: 1527px;
            right: 320px;
            z-index: 2;

            ${media.lg} {
              right: 36px;
            }

            ${media.md} {
              top: 1168px;
            }

            ${media.sm} {
              top: 1091px;
              right: 100px;
              width: 171px;
              height: 112px;
            }

            ${media.xs} {
              top: 1110px;
              right: 30px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/cloud-2-2.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>

        <Parallax
          speed={30}
          css={css`
            width: 656px;
            height: 599px;
            position: absolute;
            top: 2218px;
            left: 246px;
            z-index: 2;
            image-rendering: pixelated;

            ${media.lg} {
              left: -90px;
            }

            ${media.md} {
              top: 1565px;
              left: 12px;
              width: 386px;
              height: 353px;
            }

            ${media.sm} {
              top: 1550px;
              left: 7px;
              width: 275px;
              height: 251px;
            }

            ${media.xs} {
              top: 1612px;
              left: -30px;
              width: 214px;
              height: 195px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/planet-3.png`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>
        <Parallax
          speed={40}
          css={css`
            width: 887px;
            height: 458px;
            position: absolute;
            top: 2083px;
            left: 111px;
            z-index: 1;
            background-color: transparent;

            ${media.lg} {
              left: -50px;
            }

            ${media.md} {
              left: 24px;
              width: 656px;
              height: 308px;
              top: 1485px;
            }

            ${media.sm} {
              top: 1490px;
              left: 9px;
              width: 388px;
              height: 201px;
            }

            ${media.xs} {
              top: 1560px;
              left: -20px;
              width: 302px;
              height: 166px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/cloud-3.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>

        <Parallax
          speed={200}
          css={css`
            width: 140px;
            height: 1000px;
            position: absolute;
            top: 1981px;
            right: 10%;
            z-index: 0;

            ${media.lg} {
              right: 43px;
            }

            ${media.md} {
              width: 70px;
              height: 507px;
            }

            ${media.sm} {
              top: 1296px;
              width: 51px;
              height: 375px;
            }

            ${media.xs} {
              width: 44px;
              height: 324px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/rocket-big.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>

        <Parallax
          speed={50}
          css={css`
            width: 802px;
            height: 200px;
            position: absolute;
            top: 3071px;
            left: -240px;
            z-index: 0;

            ${media.md} {
              width: 375px;
              height: 97px;
              top: 2053px;
              left: -90px;
            }

            ${media.sm} {
              width: 312px;
              height: 81px;
              top: 1969px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/cloud-2-1.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>

        <Parallax
          speed={40}
          css={css`
            width: 822px;
            height: 520px;
            position: absolute;
            bottom: 785px;
            right: 178px;
            z-index: 0;
            image-rendering: pixelated;

            ${media.lg} {
              right: -169px;
            }

            ${media.md} {
              width: 420px;
              height: 265px;
              right: 144px;
              bottom: 824px;
            }

            ${media.sm} {
              bottom: 764px;
              width: 326px;
              height: 206px;
            }

            ${media.xs} {
              bottom: 675px;
              right: 42px;
              width: 254px;
              height: 160px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/planet-4.png`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>
        <Parallax
          speed={30}
          css={css`
            width: 1144px;
            height: 530px;
            position: absolute;
            bottom: 680px;
            right: -268px;
            z-index: 0;

            ${media.lg} {
              right: -700px;
            }

            ${media.md} {
              width: 620px;
              height: 265px;
              right: -150px;
            }

            ${media.sm} {
              bottom: 710px;
              right: -120px;
              width: 510px;
              height: 186px;
            }

            ${media.xs} {
              right: -180px;
              bottom: 640px;
              width: 426px;
              height: 156px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/cloud-4.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>

        <Parallax
          speed={120}
          css={css`
            width: 69px;
            height: 503px;
            position: absolute;
            bottom: 499px;
            left: 320px;
            z-index: 0;

            ${media.md} {
              width: 53px;
              height: 389px;
              left: 120px;
            }

            ${media.sm} {
              bottom: 298px;
              width: 43px;
              height: 316px;
            }

            ${media.xs} {
              bottom: 250px;
              left: 80px;
              width: 33px;
              height: 245px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/rocket-small.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>
        <Parallax
          css={css`
            width: 348px;
            height: 308px;
            position: absolute;
            bottom: 315px;
            left: 465px;
            z-index: 0;
            image-rendering: pixelated;

            ${media.lg} {
              left: 99px;
            }

            ${media.md} {
              width: 278px;
              height: 246px;
              left: 156px;
            }

            ${media.sm} {
              left: 147px;
              bottom: 154px;
              width: 225px;
              height: 199px;
            }

            ${media.xs} {
              left: 100px;
              width: 176px;
              height: 155px;
            }
          `}
          speed={20}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/planet-5.png`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>
        <Parallax
          speed={5}
          css={css`
            width: 388px;
            height: 254px;
            position: absolute;
            bottom: 339px;
            left: 174px;
            z-index: 0;

            ${media.lg} {
              left: -195px;
            }

            ${media.md} {
              width: 309px;
              height: 202px;
              left: -70px;
            }

            ${media.sm} {
              left: -43px;
              bottom: 169px;
              width: 251px;
              heighy: 164px;
            }

            ${media.xs} {
              width: 194px;
              height: 127px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section5/cloud-5.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>
      </ParallaxProvider>
    </section>
  );
};

const BGWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BG = styled(Parallax)<{ order: number; height: number }>`
  width: 100%;
  height: ${props => props.height}px;
  background-image: url('${S3_ASSET_HOST_URL}/front/image/section5/section5-${props =>
    props.order}.webp');
  background-size: 1600px ${props => props.height}px;
  background-position: center top;
  background-repeat: no-repeat;
`;

export default Section5;
