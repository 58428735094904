import React from 'react';
import { css } from '@emotion/react';
import GridContainer from 'components/common/GridContainer';
import GridItem from 'components/common/GridItem';
import Button from 'components/common/Button';
import { media } from 'styles/theme';
import { S3_ASSET_HOST_URL } from 'constants/url';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const Section3 = () => {
  const handleWhiteLIstButton = () => {
    window.open('https://discord.gg/bitportal');
  };
  return (
    <section
      css={css`
        max-width: 100%;
        overflow: hidden;
        padding-top: 200px;
        background-color: #151371;
        height: 1564px;
        position: relative;
        background-attachment: fixed;

        ${media.md} {
          height: 772px;
        }

        ${media.sm} {
          padding-top: 150px;
        }

        ${media.xs} {
          padding-top: 100px;
          height: 625px;
        }
      `}
    >
      <GridContainer
        justifyContents={'center'}
        alignItems={'center'}
        css={css`
          margin-bottom: 12px;
        `}
      >
        <GridItem md={4} sm={6} xs={10}>
          <span
            css={css`
              display: inline-block;
              text-align: center;
              position: relative;
              color: #fff;
              text-align: center;
              font-family: Poppins;
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: 175%; /* 49px */
              opacity: 0.96;
              z-index: 2;
              max-width: 467px;

              ${media.md} {
                font-size: 18px;
              }
            `}
          >
            Get whitelisted now for the mint
          </span>
        </GridItem>
      </GridContainer>
      <GridContainer
        justifyContents="center"
        css={css`
          margin-bottom: 64px;
        `}
      >
        <GridItem xl={12} xs={12}>
          <span
            css={css`
              width: 100%;
              display: inline-block;
              position: relative;
              z-index: 2;
              text-align: center;
              font-family: Tomorrow;
              font-size: 56px;
              font-style: italic;
              font-weight: 700;
              //line-height: 100%; /* 56px */
              letter-spacing: -1.12px;
              background: linear-gradient(90deg, #87ff9a 0%, #6cd3ff 47.92%, #ab69ff 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              ${media.md} {
                font-size: 36px;
              }
            `}
          >
            Coming Soon
          </span>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContents="center">
        <GridItem
          xl={12}
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <Button background={'--bt-p-1'} shadow={'purple'} onClick={handleWhiteLIstButton}>
            GET WHITELISTED
          </Button>
        </GridItem>
      </GridContainer>
      <div
        css={css`
          width: 100%;
          height: 15%;
          position: absolute;
          top: 0;
          z-index: 0;
          background-image: url('${S3_ASSET_HOST_URL}/front/image/section3/section3-5.webp');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 100% 0;
          background-color: transparent;

          ${media.md} {
            display: none;
          }
        `}
      ></div>
      <div
        css={css`
          width: 100%;
          height: 15%;
          position: absolute;
          top: 18%;
          z-index: 0;
          background-image: url('${S3_ASSET_HOST_URL}/front/image/section3/section3-5.webp');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 80% 0;
          background-color: transparent;
        `}
      ></div>
      <ParallaxProvider>
        <Parallax
          speed={-20}
          css={css`
            width: 767px;
            height: 570px;
            position: absolute;
            left: 0;
            bottom: 630px;
            z-index: 0;

            ${media.lg} {
              left: -383px;
            }

            ${media.md} {
              left: 50px;
              bottom: 230px;
              width: 252px;
              height: 188px;
            }

            ${media.sm} {
              left: -50px;
              bottom: 150px;
              width: 236px;
              height: 175px;
            }

            ${media.xs} {
              width: 187px;
              height: 138px;
              left: -70px;
              bottom: 200px;
            }
          `}
        >
          <img
            src={`${S3_ASSET_HOST_URL}/front/image/section3/section3-1.webp`}
            css={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Parallax>
        <Parallax
          translateY={[-20, 35]}
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section3/section3-2.png');
            background-size: 3099px 963px;
            background-position: center bottom;
            background-repeat: repeat-x;
            background-color: transparent;

            ${media.md} {
              background-size: 1472px 351px;
            }

            ${media.sm} {
              background-size: 1186px 285px;
            }

            ${media.xs} {
              background-size: 940px 245px;
            }
          `}
        />
        <Parallax
          translateY={[-10, 20]}
          css={css`
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section3/section3-3.png');
            background-size: 3099px 1047px;
            background-position: center bottom;
            background-repeat: repeat-x;
            background-color: transparent;

            ${media.md} {
              background-size: 1472px 381px;
            }

            ${media.sm} {
              background-size: 1170px 312px;
            }

            ${media.xs} {
              background-size: 940px 252px;
            }
          `}
        />
      </ParallaxProvider>
      <div
        css={css`
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          background-image: url('${S3_ASSET_HOST_URL}/front/image/section3/section3-4.png');
          background-size: 2867px 745px;
          background-repeat: repeat-x;
          background-position: center bottom;
          background-color: transparent;

          ${media.md} {
            background-size: 1472px 321px;
          }

          ${media.sm} {
            background-size: 1186px 255px;
          }

          ${media.xs} {
            background-size: 940px 202px;
          }
        `}
      />
    </section>
  );
};

export default Section3;
