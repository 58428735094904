import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Head, { TitleType } from 'components/common/Head';
import BaseContainer from 'components/common/BaseContainer';
import GridContainer from 'components/common/GridContainer';
import GridItem from 'components/common/GridItem';
import { media } from '../../styles/theme';
import { S3_ASSET_HOST_URL } from '../../constants/url';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const Terms = () => {
  return (
    <>
      <Head title={TitleType.TOS} />
      <Container>
        <BaseContainer>
          <GridContainer justifyContents="center">
            <GridItem xl={8} lg={8} md={11} sm={12} xs={12}>
              <PageTitle>Terms of Service</PageTitle>
              <ContentWrapper gap={16}>
                <Text typo="title1">Terms and Conditions</Text>
                <Text typo="caption">
                  Please read these terms and conditions ("Terms", "Terms and Conditions") carefully
                  before using our NFT project “Bit Portal” (the "Service") operated by “Fungeeble
                  Ltd.” ("us", "we", or "our"). By accessing or using the Service, you agree to be
                  bound by these Terms. If you disagree with any part of the terms, then you may not
                  access the Service.
                </Text>
              </ContentWrapper>

              <ContentWrapper gap={16} margin="52px 0 0">
                <div>
                  <Text typo="title1">NFT Ownership and Licensing</Text>
                </div>
                <div>
                  <Text typo="title2">1.1 &nbsp; Owenership</Text>
                  <Text typo="caption-purple">
                    The NFTs (Non-Fungible Tokens) purchased or acquired through our platform
                    represent a limited-edition, unique digital asset, but we retain the
                    intellectual property rights associated with the NFTs and the underlying
                    content.
                  </Text>
                </div>
                <div>
                  <Text typo="title2">1.2 &nbsp; Licensing</Text>
                  <Text typo="caption-purple">
                    By purchasing or acquiring an NFT, you receive a personal, non-transferable
                    license to use and display the NFT for personal, non-commercial purposes,
                    subject to these Terms.
                  </Text>
                </div>
              </ContentWrapper>

              <ContentWrapper gap={16} margin="52px 0 0">
                <Text typo="title1">Eligibility</Text>

                <div>
                  <Text typo="title2">2.1 &nbsp; Age Requirement</Text>
                  <Text typo="caption-purple">
                    You must be at least 18 years old or the legal age of majority in your
                    jurisdiction to access and use our Service.
                  </Text>
                </div>
                <div>
                  <Text typo="title2">2.2 &nbsp; Prohibited Jurisdictions</Text>
                  <Text typo="caption-purple">
                    The Service may not be available in certain jurisdictions due to legal
                    restrictions. It is your responsibility to ensure compliance with local laws
                    before using the Service.
                  </Text>
                </div>
              </ContentWrapper>

              <ContentWrapper gap={24} margin="52px 0 0">
                <ContentWrapper gap={16}>
                  <Text typo={'title1'}>User Conduct</Text>
                </ContentWrapper>
                <ContentWrapper>
                  <Text typo={'title2'}>3.1 &nbsp; Prohibited Activities</Text>
                  <Text typo="caption">
                    You agree not to engage in any of the following activities :
                  </Text>
                </ContentWrapper>
                <ContentWrapper
                  gap={12}
                  css={css`
                    padding-left: 24px;
                  `}
                >
                  <Text typo="caption">a &nbsp; Violating any applicable laws or regulations.</Text>
                  <Text typo="caption">
                    b &nbsp; Infringing upon the intellectual property rights of others.
                  </Text>
                  <Text typo="caption">
                    c &nbsp; Uploading, transmitting, or distributing any content that is unlawful,
                    harmful, defamatory, obscene, or otherwise objectionable.
                  </Text>
                  <Text typo="caption">
                    d &nbsp; Attempting to gain unauthorized access to our systems or networks.
                  </Text>
                  <Text typo="caption">
                    e &nbsp; Interfering with the security or integrity of the Service or any
                    associated software.
                  </Text>
                </ContentWrapper>
              </ContentWrapper>

              <ContentWrapper gap={16} margin="52px 0 0">
                <Text typo="title1">Intellectual Property</Text>

                <div>
                  <Text typo="title2">4.1 &nbsp; Trademarks and Copyrightst</Text>
                  <Text typo="caption">
                    All trademarks, logos, and service marks displayed on the Service are the
                    property of their respective owners. All content provided on the Service,
                    including text, graphics, images, videos, and software, is protected by
                    applicable copyright laws.
                  </Text>
                </div>
                <div>
                  <Text typo="title2">4.2 &nbsp; Limited License</Text>
                  <Text typo="caption">
                    You are granted a limited, non-exclusive, non-transferable license to access and
                    use the Service and its content for personal, non-commercial purposes.
                  </Text>
                </div>
              </ContentWrapper>

              <ContentWrapper gap={16} margin="52px 0 0">
                <Text typo="title1">Disclaimer of Warranty</Text>

                <div>
                  <Text typo="title2">5.1 &nbsp; As-Is Basis</Text>
                  <Text typo="caption">
                    The Service and its content are provided on an "as-is" and "as available" basis
                    without any warranties or representations of any kind, whether express or
                    implied. 5.2 No Guarantee: We do not guarantee the availability, accuracy,
                    completeness, or reliability of the Service or any content therein.
                  </Text>
                </div>
                <div>
                  <Text typo="title2">5.2 &nbsp; No Guarantee</Text>
                  <Text typo="caption">
                    We do not guarantee the availability, accuracy, completeness, or reliability of
                    the Service or any content therein.
                  </Text>
                </div>
              </ContentWrapper>

              <ContentWrapper gap={16} margin="52px 0 0">
                <Text typo="title1">Limitation of Liability</Text>

                <div>
                  <Text typo="title2">6.1 &nbsp; No Liability</Text>
                  <Text typo="caption">
                    We, our affiliates, partners, and employees shall not be liable for any direct,
                    indirect, incidental, special, consequential, or exemplary damages arising out
                    of or in connection with your use of the Service or any NFTs.
                  </Text>
                </div>
                <div>
                  <Text typo="title2">6.2 &nbsp; Indemnification</Text>
                  <Text typo="caption">
                    You agree to indemnify and hold us harmless from any claims, losses, damages,
                    liabilities, costs, or expenses arising out of or related to your use of the
                    Service or any violation of these Terms.
                  </Text>
                </div>
              </ContentWrapper>

              <ContentWrapper gap={16} margin="52px 0 0">
                <Text typo="title1">Termination</Text>

                <div>
                  <Text typo="title2">7.1 &nbsp; Termination</Text>
                  <Text typo="caption">
                    We reserve the right to terminate or suspend your access to the Service at any
                    time without prior notice or liability.
                  </Text>
                </div>
                <div>
                  <Text typo="title2">7.2 &nbsp; Effect of Termination</Text>
                  <Text typo="caption">
                    Upon termination, all licenses and rights granted to you under these Terms will
                    immediately cease, and you must stop using the Service and delete any copies of
                    NFTs acquired through the Service.
                  </Text>
                </div>
              </ContentWrapper>

              <ContentWrapper gap={16} margin="52px 0 0">
                <Text typo="title1">Governing Law</Text>

                <div>
                  <Text typo="title2">8.1 &nbsp; Applicable Law</Text>
                  <Text typo="caption">
                    These Terms shall be governed by and construed in accordance with the laws of
                    the British Virgin Island, without regard to its conflict of law provisions.
                  </Text>
                </div>
                <div>
                  <Text typo="title2">8.2 &nbsp; Jurisdiction</Text>
                  <Text typo="caption">
                    Any disputes arising under these Terms shall be subject to the exclusive
                    jurisdiction of the courts in the Eastern Caribbean Supreme Court.
                  </Text>
                </div>
              </ContentWrapper>

              <ContentWrapper gap={16} margin="52px 0 0">
                <Text typo="title1">Modifications</Text>

                <div>
                  <Text typo="title2">9.1 &nbsp; Changes to Terms</Text>
                  <Text typo="caption">
                    We reserve the right to modify or replace these Terms at any time. The most
                    current version of the Terms will be posted on our website.
                  </Text>
                </div>
                <div>
                  <Text typo="title2">9.2 &nbsp; Notice</Text>
                  <Text typo="caption">
                    We may provide you with notice of changes to the Terms by posting a prominent
                    notice on the Service or by sending you an email. By using our NFT project, you
                    acknowledge that you have read, understood, and agree to be bound by these Terms
                    and Conditions.
                  </Text>
                </div>
              </ContentWrapper>

              <Line></Line>
              <ContentWrapper>
                <ContentWrapper>
                  <Text typo="caption">
                    If you have any questions or concerns about these Terms, please contact us at
                    support@bitportal.xyz.
                  </Text>
                </ContentWrapper>
              </ContentWrapper>
            </GridItem>
          </GridContainer>
        </BaseContainer>
        <BGWrapper>
          <ParallaxProvider>
            <BG speed={-40} />
            <BG speed={-70} isReverse />
            <BG speed={-180} />
            <BG speed={-40} isReverse />
            <BG speed={-50} />
            <BottomBG />
            <TopCharacter speed={-20} />
            <LeftIce />
            <RightRocket speed={120} />
            <RightPlanet />
            <FallCharacter speed={-30} />
            <BottomCat />
          </ParallaxProvider>
        </BGWrapper>
      </Container>
    </>
  );
};

const wobble = css`
  @keyframes wobble {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  animation: wobble 1s infinite ease-in-out alternate;
`;

const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 168px 0 180px;
  background: linear-gradient(180deg, #040538 0%, #6a2693 100%) fixed;
  overflow: hidden;
`;

const PageTitle = styled.h1`
  position: relative;
  z-index: 1;
  margin: 0 0 16px 0;
  color: #fff;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Tomorrow;
  font-size: 36px;
  font-style: italic;
  font-weight: 700;
  line-height: 155%; /* 55.8px */

  ${media.xs} {
    font-size: 32px;
  }
`;

const Text = styled.div<{ typo: 'title1' | 'title2' | 'caption' | 'caption-purple' }>`
  position: relative;
  z-index: 1;
  color: #fff;
  ${({ typo }) =>
    typo === 'title1'
      ? css`
          color: #fff;
          font-variant-numeric: lining-nums tabular-nums;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 36px */

          ${media.xs} {
            font-size: 20px;
          }
        `
      : typo === 'title2'
      ? css`
          color: #fff;
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 175%; /* 35px */

          ${media.xs} {
            font-size: 16px;
          }
        `
      : typo === 'caption'
      ? css`
          color: #fafbfc;
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%; /* 28px */

          ${media.xs} {
            font-size: 14px;
          }
        `
      : css`
          color: #d1bdeb;
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%; /* 28px */

          ${media.xs} {
            font-size: 14px;
          }
        `}
`;

const ContentWrapper = styled.div<{ gap?: number; margin?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
  margin: ${({ margin }) => margin};
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  margin: 56px 0 24px;
  border: none;
  background: #a06ace;
`;

const BGWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BG = styled(Parallax)<{ isReverse?: boolean }>`
  width: 100%;
  height: 842px;
  background-image: url('${S3_ASSET_HOST_URL}/front/image/privacy/${props =>
    props.isReverse ? 'bg-reverse.webp' : 'bg.webp'}');
  background-size: 2382px 842px;
  background-position: center top;
  background-repeat: repeat-x;
`;

const BottomBG = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 273px;
  background-image: url('${S3_ASSET_HOST_URL}/front/image/privacy/bottom-bg.png');
  background-size: 2791px 273px;
  background-position: center 130px;
  background-repeat: repeat-x;
`;

const RightRocket = styled(Parallax)`
  position: absolute;
  top: 40%;
  right: 150px;

  width: 53px;
  height: 392px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/privacy/left-rocket.webp');
  background-size: contain;

  ${media.lg} {
    right: 20px;
    width: 36px;
    height: 267px;
  }

  ${media.md} {
    display: none;
  }
`;

const TopCharacter = styled(Parallax)`
  position: absolute;
  top: 0;
  left: 200px;
  z-index: 0;
  width: 102px;
  height: 243px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/section6/absolute6-1.webp');
  background-size: contain;

  ${media.lg} {
    top: -150px;
    left: 0;
    width: 88px;
    height: 183px;
  }

  ${media.md} {
    width: 68px;
    height: 163px;
  }

  ${media.sm} {
    width:;
  }
`;

const LeftIce = styled.div`
  position: absolute;
  top: 20%;
  left: 100px;

  width: 257px;
  height: 310px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/terms/left-planet.webp');

  ${wobble};

  ${media.lg} {
    left: -20px;
  }
`;

const RightPlanet = styled.div`
  position: absolute;
  top: 50%;
  right: 100px;
  width: 63px;
  height: 63px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/terms/right-planet.webp');
  background-size: contain;
  background-repeat: no-repeat;

  ${wobble};

  ${media.lg} {
    right: -20px;
    width: 159px;
    height: 103px;
  }

  ${media.md} {
    width: 85px;
    height: 54px;
  }
`;

const FallCharacter = styled(Parallax)`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 200px;
  transform: translateX(-50%);

  width: 127px;
  height: 133px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/section6/absolute6-4.webp');
  background-size: contain;

  ${media.lg} {
    display: none;
  }
`;

const BottomCat = styled.div`
  position: absolute;
  bottom: 10%;
  right: 300px;
  width: 63px;
  height: 63px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/section6/absolute6-2.webp');
  background-size: contain;
  background-repeat: no-repeat;

  ${wobble};

  ${media.lg} {
    right: 10px;
  }

  ${media.md} {
    right: 0;
    bottom: 30%;
  }
`;

export default Terms;
