import { forwardRef, HTMLAttributes, memo, PropsWithChildren, Ref } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

// styles
import { breakPoints } from 'styles/theme';

const GridItem = (
  { xl, lg, md, sm, xs, ...rest }: GridColumnType & HTMLAttributes<HTMLDivElement>,
  ref?: Ref<HTMLDivElement>,
) => {
  return (
    <>
      <StyledGridItem xl={xl} lg={lg} md={md} sm={sm} xs={xs} ref={ref} {...rest}></StyledGridItem>
    </>
  );
};

const GRID_QTY = 12;

const generateFlexWidth = (columnSize?: GridColumnQtyType) => {
  let style;
  switch (typeof columnSize) {
    case 'string':
      style = css`
        flex: 0 0 auto;
      `;
      break;
    case 'number':
      style = css`
        max-width: ${(columnSize / GRID_QTY) * 100}%;
        flex: 1 1 ${(columnSize / GRID_QTY) * 100}%;
      `;
      break;
    case 'boolean':
      style = css`
        flex: ${columnSize ? '1 0 0' : '1 0 0'};
      `;
      break;
    default:
      style = css`
        flex: 0 0 auto;
      `;
  }

  return style;
};

const StyledGridItem = styled.div<GridColumnType>`
  max-width: 100%;
  flex: 0 0 auto;

  // 836px 초과
  @media (min-width: ${({ theme: { breakPoints } }) => breakPoints.lg + 1}px) {
    ${({ xl }) => generateFlexWidth(xl)};
  }

  // 1300px 이하
  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    ${({ lg }) => generateFlexWidth(lg)};
  }

  // 836px 이하
  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    ${({ md }) => generateFlexWidth(md)};
  }

  // 600px 이하
  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    ${({ sm }) => generateFlexWidth(sm)};
  }

  // 360px 이하
  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    ${({ xs }) => generateFlexWidth(xs)};
  }
`;

// media에 정의된 viewPort 사이즈를 기반으로 그리드 뷰포트 사이즈 생성
type GridViewPortSizeType = keyof typeof breakPoints;
type GridColumnQtyType =
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 4.5
  | 5
  | 5.5
  | 6
  | 6.5
  | 7
  | 7.5
  | 8
  | 8.5
  | 9
  | 9.5
  | 10
  | 10.5
  | 11
  | 12
  | boolean
  | 'auto';

type GridColumnType = Partial<Record<GridViewPortSizeType, GridColumnQtyType>>;

export default memo(forwardRef(GridItem));
