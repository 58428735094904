import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import Header from './Header';
import Footer from './Footer';
import { media } from '../../styles/theme';

const Layout = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  return (
    <LayoutContainer>
      <Header />
      {children}
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;

const LayoutContainer = styled.main`
  width: 100%;
  padding-top: 88px; // Header height

  ${media.xs} {
    padding-top: 60px; // Header height
  }
`;
