import { css } from '@emotion/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import GridContainer from '../common/GridContainer';
import GridItem from '../common/GridItem';
import BaseContainer from '../common/BaseContainer';
import { media } from '../../styles/theme';
import { S3_ASSET_HOST_URL } from 'constants/url';
const MINT_LOGIC = [
  'User connects wallet and requests to mint',
  'Bit Portal mints duplicate tokens on each chain',
  'Contract unlocks one token on the selected chain and send the token to user’s wallet',
];

const SWITCH_LOGIC = [
  'User interacts with Bit Portal smart contract with switch signature in order to switch tokens to a different chain',
  'User’s start token gets sent to the Bit Portal contract wallet',
  'Bit Portal contract verifies user’s switch signature along with the token receipt',
  'User receives destination token from the Bit Portal contract wallet',
  'Bit Portal ensures that only one token can be unlocked at a time through dual signature on the smart contract',
];

const Section7 = () => {
  const mintImgRef = useRef<HTMLDivElement>(null);
  const switchImgRef = useRef<HTMLDivElement>(null);
  const [mintOrder, setMintOrder] = useState(1);
  const [switchOrder, setSwitchOrder] = useState(1);
  /**
   * @desc item의 인덱스와 현재 animate order를 비교하여 opacity 반환
   * 현재 stkciy로 걸려있는 article의 animation order와 item의 인덱스가 같다면 opacity 1 반환
   * 둘의 차가 1이라면 0.4
   * 그 외의 경우엔 0.16을 반환시켜서 현재 하이라이트된 아이템과 순서가 멀리 있음을 강조
   */
  const getOpacity = useCallback(
    (currentOrder: number, itemIndex: number) => {
      const _currentOrder = currentOrder - 1; // 배열 원소의 인덱스인 itemIndex와 비교를 위해 -1

      if (_currentOrder === itemIndex) {
        return 1;
      } else {
        return 0.16;
      }
    },
    [mintOrder, switchOrder],
  );

  // TODO throttle 적용
  const handleScroll = throttle(() => {
    // sticky element인 article의 offset을 기준으로 애니메이션 구간 정의
    // article Height = offsetHeight
    // article offset = offsetTop
    // 시작 -> offsetTop이 0보다 커질때 시작
    // 끝 -> offsetTop이 offsetHeight과 같아지면 애니메이션 끝
    // offsetHeight를 로직의 갯수등분 하여 구간마다 애니메이션 실행
    // mint는 1구간에선 1 -> 1, 2 -> 0.68, 3 -> 0.32
    // switch는 1구간에선 1-> 1, 2 -> 0.68, 3,4,5 -> 0.32
    // 2구간에선 1 -> 0.68, 2 -> 1, 3 -> 0.68, 4,5 => 0.32
    // 3구간에선 1 -> 0.32, 2 -> 0.68, 3 -> 1, 4 -> 0.68, 5 -> 0.32
    // 4,5 구간은 1,2 구간의 역순

    if (mintImgRef.current) {
      const { offsetTop, offsetHeight } = mintImgRef.current;
      if (offsetTop > 0) {
        const animateOrder = Math.ceil(offsetTop / ((offsetHeight * 5) / 3)); // 1 | 2 | 3
        setMintOrder(() => animateOrder);
      }
    }

    if (switchImgRef.current) {
      const { offsetTop, offsetHeight } = switchImgRef.current;
      if (offsetTop) {
        const animateOrder = Math.ceil(offsetTop / ((offsetHeight * 7) / 5)); // 1 | 2 | 3 | 4 | 5
        setSwitchOrder(() => animateOrder);
      }
    }
  }, 300);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <BaseContainer
        style={css`
          position: relative;
          background: #0d1320;
          min-height: 600vh;
        `}
      >
        <article
          ref={mintImgRef}
          css={css`
            flex: 1;
            width: 100%;
            height: 100vh;
            margin: 0 auto;
            position: sticky;
            top: 0;
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100%;
              transform: translate(-50%, -50%);
            `}
          >
            <h1
              css={css`
                margin-top: 0;
                padding-top: 32px;
                color: #d8e4ff;
                text-align: right;
                font-family: Tomorrow;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 48px */
                letter-spacing: 2.4px;
                text-transform: uppercase;

                ${media.xs} {
                  margin-bottom: 40px;
                  text-align: center;
                  font-size: 32px;
                }
              `}
            >
              MINT Logic
            </h1>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                height: auto;
              `}
            >
              <GridContainer
                justifyContents="space-between"
                css={css`
                  position: sticky;
                  top: 0;
                  flex-wrap: nowrap;
                  gap: 56px;

                  ${media.xs} {
                    gap: 28px;
                    flex-direction: column-reverse;
                    flex-wrap: wrap;
                  }
                `}
              >
                <GridItem xl={6} lg={6} md={5.5} sm={6} xs={12}>
                  {MINT_LOGIC.map((mint, index) => {
                    return (
                      <li
                        key={index}
                        css={css`
                          display: flex;
                          gap: 16px;
                          color: #fff;
                          opacity: ${getOpacity(mintOrder, index)};
                          transition: opacity 0.21s;
                          margin-bottom: 16px;
                        `}
                      >
                        <div
                          css={css`
                            min-width: 32px;
                            max-width: 32px;
                            font-family: ThaleahFat;
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%; /* 48px */

                            ${media.md} {
                              min-width: 16px;
                              max-width: 16px;
                              font-size: 32px;
                            }
                          `}
                        >
                          {index + 1}
                        </div>
                        <div
                          css={css`
                            font-family: Poppins;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 175%; /* 42px */

                            ${media.md} {
                              font-size: 15px;
                            }
                          `}
                        >
                          {mint}
                        </div>
                      </li>
                    );
                  })}
                </GridItem>
                <GridItem
                  xl={6}
                  lg={6}
                  md={6}
                  sm={5.5}
                  xs={12}
                  css={css`
                    width: 100%;
                    height: 100%;
                    background-image: url('${S3_ASSET_HOST_URL}/front/image/section7/section7-frame.webp');
                    padding: 10% 8%;
                    background-size: 100% 100%;
                    text-align: center;

                    ${media.sm} {
                      display: flex;
                      justify-content: center;
                    }
                  `}
                >
                  <img
                    src={`${S3_ASSET_HOST_URL}/front/image/section7/section7-1.gif`}
                    alt=""
                    css={css`
                      width: 100%;
                    `}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </article>
      </BaseContainer>

      <BaseContainer
        style={css`
          position: relative;
          background: #0d1320;
          min-height: 800vh;
        `}
      >
        <article
          ref={switchImgRef}
          css={css`
            width: 100%;
            height: 100vh;
            margin: 0 auto;
            position: sticky;
            top: 0;
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100%;
              transform: translate(-50%, -50%);
            `}
          >
            <h1
              css={css`
                margin-top: 0;
                padding-top: 32px;
                color: #d8e4ff;
                text-align: left;
                font-family: Tomorrow;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 48px */
                letter-spacing: 2.4px;
                text-transform: uppercase;

                ${media.xs} {
                  margin-bottom: 40px;
                  font-size: 32px;
                  text-align: center;
                }
              `}
            >
              SWITCH Logic
            </h1>
            <GridContainer
              justifyContents="space-between"
              alignItems="flex-start"
              css={css`
                height: auto;
                flex-wrap: nowrap;
                gap: 56px;
                ${media.xs} {
                  gap: 28px;
                  flex-direction: column;
                  flex-wrap: wrap;
                }
              `}
            >
              <GridItem
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                css={css`
                  width: 100%;
                  height: auto;
                  background-image: url('${S3_ASSET_HOST_URL}/front/image/section7/section7-frame.webp');
                  padding: 10% 8%;
                  background-size: 100% 100%;
                  text-align: center;

                  ${media.sm} {
                    display: flex;
                    justify-content: center;
                  }
                `}
              >
                <img
                  src={`${S3_ASSET_HOST_URL}/front/image/section7/section7-2.gif`}
                  alt=""
                  css={css`
                    width: 100%;
                  `}
                />
              </GridItem>
              <GridItem
                xl={6}
                lg={6}
                md={5.5}
                sm={5.5}
                xs={12}
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  position: sticky;
                  top: 0;
                `}
              >
                <ul>
                  {SWITCH_LOGIC.map((mint, index) => {
                    return (
                      <li
                        key={index}
                        css={css`
                          display: flex;
                          gap: 16px;
                          color: #fff;
                          opacity: ${getOpacity(switchOrder, index)};
                          transition: opacity 0.21s;
                          margin-bottom: 16px;
                        `}
                      >
                        <div
                          css={css`
                            min-width: 32px;
                            max-width: 32px;
                            font-family: ThaleahFat;
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%; /* 48px */

                            ${media.md} {
                              min-width: 16px;
                              max-width: 16px;
                              font-size: 32px;
                            }
                          `}
                        >
                          {index + 1}
                        </div>
                        <div
                          css={css`
                            font-family: Poppins;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 175%; /* 42px */

                            ${media.md} {
                              font-size: 15px;
                            }
                          `}
                        >
                          {mint}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </GridItem>
            </GridContainer>
          </div>
        </article>
      </BaseContainer>
    </>
  );
};

export default Section7;
