import { css } from '@emotion/react';
import { media } from 'styles/theme';
import { S3_ASSET_HOST_URL } from 'constants/url';

const ROADMAP = [
  {
    image: `${S3_ASSET_HOST_URL}/front/image/section10/section10-3.gif`,
    title: 'PHASE 1',
    subTitle: 'BUILD THE COMMUNITY',
    desc: 'Bit Portal team is diligently establishing a robust Discord community, fostering an interactive space where prospective holders can engage in various activities, such as gaming, sharing insights, and earning rewards. This community-building initiative aims to cultivate a vibrant and supportive ecosystem, providing opportunities for holders to connect, collaborate, and immerse themselves in the Bit Portal experience.',
  },
  {
    image: `${S3_ASSET_HOST_URL}/front/image/section10/section10-4.gif`,
    title: 'PHASE 2',
    subTitle: 'Launch the bit Portal console',
    desc:
      '"Bit Portal Console" tool will be introduced to empower Bit Portal holders with the ability to switch their NFTs across different chains at their convenience. Users gain full control over their NFTs, enabling effortless movement and transfer of assets across different chains.\n' +
      '\n' +
      'Long-term objective for the Bit Portal Console is to extend its functionality beyond Bit Portal, where existing NFT projects can effortlessly leverage the console to transform their projects into multi-chain NFTs. This collaborative approach seeks to promote interoperability within the NFT ecosystem, enhancing accessibility and unlocking new possibilities for both creators and holders',
  },
  {
    image: `${S3_ASSET_HOST_URL}/front/image/section10/section10-5.gif`,
    title: 'PHASE 3',
    subTitle: 'maximize chain support',
    desc:
      'Bit Portal is dedicated to facilitate chain-switching capabilities for its holders across a wide range of chains. At first, Bit Portal will encompass efficient chains such as Ethereum, Solana, and Polygon.\n' +
      '\n' +
      "The project's commitment remains unwavering in expanding the available chains within the Bit Portal ecosystem. The team will closely monitor emerging chains and assess their popularity and efficiency to determine future additions.",
  },
  {
    image: `${S3_ASSET_HOST_URL}/front/image/section10/section10-6.gif`,
    title: 'PHASE 4',
    subTitle: 'launch the bit Portal dashboard',
    desc:
      'To drive financial prosperity for Bit Portal holders, Bit Portal dashboard will be introduced. The dashboard is a powerful tool that empowers holders by providing a comprehensive view of arbitrage opportunities within the dynamic cross-chain NFT market.\n' +
      '\n' +
      'Through the dashboard, holders gain access to real-time data and insights, enabling them to identify and capitalize on arbitrage opportunities on cross-chain NFTs swiftly and effectively. This feature equips holders with the necessary information to make informed trading decisions, ultimately maximizing their potential for financial success within the NFT ecosystem.',
  },
];

const Section10 = () => {
  return (
    <section
      css={css`
        position: relative;
        min-height: 100vh;
        padding: 192px 16px 164px;
        background: linear-gradient(180deg, #0d1320 0%, #512794 63.52%);
      `}
    >
      <img
        src={`${S3_ASSET_HOST_URL}/front/image/section10/section10-1.gif`}
        alt=""
        css={css`
          position: absolute;
          z-index: 0;
          width: auto;
          height: auto;
          top: 10%;
          left: 10%;
        `}
      />
      <img
        src={`${S3_ASSET_HOST_URL}/front/image/section10/section10-2.gif`}
        alt=""
        css={css`
          position: absolute;
          z-index: 0;
          width: auto;
          height: auto;
          top: 50%;
          transform: translateX(-50%);
          right: 5%;
        `}
      />
      <article
        css={css`
          max-width: 848px;
          margin: 0 auto;
        `}
      >
        <h1
          css={css`
            color: #d48eff;
            text-align: center;
            font-family: Tomorrow;
            font-size: 72px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 72px */
            text-transform: uppercase;
            margin-bottom: 144px;

            ${media.md} {
              text-align: left;
              margin-bottom: 80px;
            }

            ${media.xs} {
              text-align: center;
              font-size: 32px;
            }
          `}
        >
          Roadmap
        </h1>

        <div>
          <ul
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 40px;
            `}
          >
            {ROADMAP.map((item, index) => {
              return (
                <li
                  key={index}
                  css={css`
                    display: flex;
                    flex-direction: ${index % 2 !== 0 ? 'row-reverse' : 'row'};
                    gap: 32px;

                    ${media.md} {
                      flex-direction: column;
                    }
                  `}
                >
                  <div
                    css={css`
                      ${media.xs} {
                        text-align: center;
                      }
                    `}
                  >
                    <img
                      src={item.image}
                      alt={`roadmap image ${index + 1}`}
                      css={css`
                        position: relative;
                        z-index: 1;
                        width: 256px;
                        height: 256px;

                        ${media.xs} {
                          display: inline-block;
                          width: 100%;
                          text-align: center;
                          width: 252px;
                          height: 252px;
                        }
                      `}
                    />
                  </div>
                  <div>
                    <h2
                      css={css`
                        color: #fff;
                        font-family: ThaleahFat;
                        font-size: 56px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                        ${media.md} {
                          font-size: 44px;
                        }

                        ${media.xs} {
                          font-size: 32px;
                        }
                      `}
                    >
                      {item.title}
                    </h2>
                    <h3
                      css={css`
                        color: #fff;
                        font-family: ThaleahFat;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                        ${media.md} {
                          font-size: 32px;
                        }

                        ${media.xs} {
                          font-size: 24px;

                          margin-bottom: 16px;
                        }
                      `}
                    >
                      {item.subTitle}
                    </h3>
                    <div
                      css={css`
                        color: #fff;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 170%; /* 27.2px */
                      `}
                    >
                      {item.desc}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        {/* Background image */}
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            background-image: url('${S3_ASSET_HOST_URL}/front/image/section10/section10-7.webp');
            background-position: left 100%;
            background-size: contain;
          `}
        />
        {/* Background image */}
      </article>
    </section>
  );
};
export default Section10;
