import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Head, { TitleType } from 'components/common/Head';
import BaseContainer from 'components/common/BaseContainer';
import GridContainer from 'components/common/GridContainer';
import GridItem from 'components/common/GridItem';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { S3_ASSET_HOST_URL } from 'constants/url';
import { media } from 'styles/theme';

const Privacy = () => {
  return (
    <>
      <Head title={TitleType.PP} />
      <Container>
        <BaseContainer>
          <GridContainer justifyContents="center">
            <GridItem xl={8} lg={8} md={11} sm={12} xs={12}>
              <PageTitle>Privacy Policy</PageTitle>
              <Text typo="caption">
                This Privacy Policy describes how “Fungeeble Ltd.” ("we", "us", or "our") collects,
                uses, and protects the personal information you provide while using our NFT project
                “Bit Portal” (the "Service"). We are committed to protecting your privacy and
                ensuring the security of your personal information. By using our Service, you
                consent to the collection and use of your personal information as outlined in this
                Privacy Policy.
              </Text>

              <ContentWrapper gap={16} margin="52px 0 0">
                <div>
                  <Text typo="title1">Information We Collect</Text>
                </div>
                <div>
                  <Text typo="title2">1.1 &nbsp; Personal Information</Text>
                  <Text typo="caption">
                    When you use our Service, we may collect personal information that you
                    voluntarily provide to us, such as your name, email address, and contact
                    details. We only collect personal information that is necessary for the purposes
                    outlined in this Privacy Policy.
                  </Text>
                </div>
                <div>
                  <Text typo="title2">1.2 &nbsp; Non-Personal Information</Text>
                  <Text typo="caption">
                    We may also collect non-personal information, such as aggregated data,
                    analytics, and usage information about your interactions with the Service. This
                    information does not personally identify you and is used to improve the
                    functionality and performance of our Service.
                  </Text>
                </div>
              </ContentWrapper>

              <ContentWrapper gap={24} margin="52px 0 0">
                <ContentWrapper gap={16}>
                  <Text typo={'title1'}>Use of Information</Text>
                </ContentWrapper>
                <ContentWrapper>
                  <Text typo={'title2'}>2.1 &nbsp; Purpose</Text>
                  <Text typo="caption">
                    We may use the information we collect for the following purposes :
                  </Text>
                </ContentWrapper>
                <ContentWrapper
                  gap={12}
                  css={css`
                    padding-left: 24px;
                  `}
                >
                  <Text typo="caption">a &nbsp; To provide and maintain the Service.</Text>
                  <Text typo="caption">
                    b &nbsp; To communicate with you regarding updates, changes, or other
                    information related to the Service.
                  </Text>
                  <Text typo="caption">
                    c &nbsp; To personalize your experience and improve our Service.
                  </Text>
                  <Text typo="caption">
                    d &nbsp; To respond to inquiries, support requests, or customer service needs.
                  </Text>
                  <Text typo="caption">
                    e &nbsp; To monitor and analyze trends, usage, and activities in connection with
                    the Service.
                  </Text>
                  <Text typo="caption">
                    f &nbsp; To comply with legal obligations or protect our rights and interests.
                  </Text>
                </ContentWrapper>

                <ContentWrapper>
                  <Text typo={'title2'}>2.2 &nbsp; Third-Party Service Providers</Text>
                  <Text typo="caption">
                    We may share your information with trusted third-party service providers who
                    assist us in operating our Service, conducting our business, or servicing you.
                    These third parties are obligated to keep your information confidential and are
                    not permitted to use your personal information for any other purpose.
                  </Text>
                </ContentWrapper>
                <ContentWrapper>
                  <Text typo={'title2'}>2.3 &nbsp; Aggregated and Anonymized Data</Text>
                  <Text typo="caption">
                    We may aggregate and anonymize your personal information to create statistical
                    or demographic data. Such aggregated and anonymized data does not identify you
                    personally and may be used for any lawful purpose.
                  </Text>
                </ContentWrapper>
              </ContentWrapper>

              <ContentWrapper margin="52px 0 0">
                <Text typo="title1">Data Retention</Text>
                <Text typo="caption">
                  We will retain your personal information for as long as necessary to fulfill the
                  purposes outlined in this Privacy Policy unless a longer retention period is
                  required or permitted by law. When your personal information is no longer needed,
                  we will securely dispose of it in accordance with applicable laws and regulations.
                </Text>
              </ContentWrapper>
              <ContentWrapper margin="52px 0 0">
                <Text typo="title1">Security</Text>
                <Text typo="caption">
                  We take reasonable measures to protect the security and integrity of your personal
                  information. However, please be aware that no method of transmission over the
                  internet or electronic storage is completely secure. While we strive to use
                  commercially acceptable means to protect your personal information, we cannot
                  guarantee its absolute security.
                </Text>
              </ContentWrapper>
              <ContentWrapper margin="52px 0 0">
                <Text typo="title1">Data Retention</Text>
                <Text typo="caption">
                  We will retain your personal information for as long as necessary to fulfill the
                  purposes outlined in this Privacy Policy unless a longer retention period is
                  required or permitted by law. When your personal information is no longer needed,
                  we will securely dispose of it in accordance with applicable laws and regulations.
                </Text>
              </ContentWrapper>
              <ContentWrapper margin="52px 0 0">
                <Text typo="title1">Third-Party Links</Text>
                <Text typo="caption">
                  Our Service may contain links to third-party websites or services that are not
                  operated by us. We are not responsible for the privacy practices or content of
                  these third-party websites or services. We encourage you to review the privacy
                  policies of those third parties before providing any personal information or using
                  their services.
                </Text>
              </ContentWrapper>
              <ContentWrapper margin="52px 0 0">
                <Text typo="title1">Children's Privacy</Text>
                <Text typo="caption">
                  Our Service is not intended for children under the age of 18. We do not knowingly
                  collect personal information from individuals under 18 years of age. If you are a
                  parent or guardian and believe that your child has provided us with personal
                  information, please contact us, and we will promptly remove such information from
                  our records.
                </Text>
              </ContentWrapper>
              <ContentWrapper margin="52px 0 0">
                <Text typo="title1">Changes to this Privacy Policy</Text>
                <Text typo="caption">
                  We may update this Privacy Policy from time to time. The most current version will
                  be posted on our website with the effective date. We encourage you to review this
                  Privacy Policy periodically for any changes. Your continued use of the Service
                  after any modifications to the Privacy Policy will constitute your acknowledgment
                  and acceptance of the updated terms.
                </Text>
              </ContentWrapper>
              <Line></Line>
              <ContentWrapper>
                <Text typo="title1">Contact Us</Text>
                <ContentWrapper>
                  <Text typo="caption">
                    If you have any questions, concerns, or suggestions regarding this Privacy
                    Policy, please contact us at <u>support@bitportal.xyz.</u>
                    <br />
                    <br />
                    By using our service, you acknowledge that you have read, understood, and agree
                    to the terms of this Privacy Policy.
                  </Text>
                </ContentWrapper>
              </ContentWrapper>
            </GridItem>
          </GridContainer>
        </BaseContainer>
        <BGWrapper>
          <ParallaxProvider>
            <BG speed={-140} />
            <BG speed={-70} isReverse />
            <BG speed={-180} />
            <BG speed={-40} isReverse />
            <BG speed={-50} />
            <LeftIce />
            <LeftRocket speed={120} />
            <BottomBG />
            <BottomIce>
              <img
                src={`${S3_ASSET_HOST_URL}/front/image/privacy/bottom-character.webp`}
                className="character"
              />
              <img
                src={`${S3_ASSET_HOST_URL}/front/image/privacy/bottom-ice.png`}
                className="ice"
              />
            </BottomIce>
            <RightPlanet />
            <BottomRocket translateX={['-100px', '-1200px']} translateY={['-100px', '-300px']} />
          </ParallaxProvider>
        </BGWrapper>
      </Container>
    </>
  );
};

const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 168px 0 380px;
  background: linear-gradient(180deg, #040538 0%, #6a2693 100%);
  overflow: hidden;
`;

const PageTitle = styled.h1`
  margin: 0 0 16px 0;
  color: #fff;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Tomorrow;
  font-size: 36px;
  font-style: italic;
  font-weight: 700;
  line-height: 155%; /* 55.8px */
`;

const Text = styled.div<{ typo: 'title1' | 'title2' | 'caption' }>`
  position: relative;
  z-index: 3;
  ${({ typo }) =>
    typo === 'title1'
      ? css`
          color: #fff;
          font-variant-numeric: lining-nums tabular-nums;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 36px */
        `
      : typo === 'title2'
      ? css`
          color: #fff;
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 175%; /* 35px */
        `
      : css`
          color: #fafbfc;
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%; /* 28px */
        `};
  color: #fff;
`;

const wobble = css`
  @keyframes wobble {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  animation: wobble 1s infinite ease-in-out alternate;
`;

const ContentWrapper = styled.div<{ gap?: number; margin?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
  margin: ${({ margin }) => margin};
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  border: none;
  background: #a06ace;
`;

const BGWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BG = styled(Parallax)<{ isReverse?: boolean }>`
  width: 100%;
  height: 842px;
  background-image: url('${S3_ASSET_HOST_URL}/front/image/privacy/${props =>
    props.isReverse ? 'bg-reverse.webp' : 'bg.webp'}');
  background-size: 2382px 842px;
  background-position: center top;
  background-repeat: repeat-x;
`;

const BottomBG = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 273px;
  background-image: url('${S3_ASSET_HOST_URL}/front/image/privacy/bottom-bg.png');
  background-size: 2791px 273px;
  background-position: center 130px;
  background-repeat: repeat-x;
`;

const LeftRocket = styled(Parallax)`
  position: absolute;
  top: 40%;
  left: 300px;

  width: 53px;
  height: 392px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/privacy/left-rocket.webp');

  ${media.lg} {
    left: 10px;
    width: 36px;
    height: 267px;
  }

  ${media.md} {
    display: none;
  }
`;

const LeftIce = styled.div`
  position: absolute;
  top: 25%;
  left: -20px;

  width: 257px;
  height: 310px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/privacy/left-ice.webp');

  ${wobble}

  ${media.lg} {
    display: none;
  }
`;

const RightPlanet = styled.div`
  position: absolute;
  z-index: 0;
  top: 50%;
  right: 30px;
  width: 224px;
  height: 209px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/privacy/right-planet.webp');
  background-size: contain;
  background-repeat: no-repeat;

  ${wobble}

  ${media.lg} {
    right: -20px;
    width: 159px;
    height: 103px;
  }

  ${media.md} {
    width: 85px;
    height: 54px;
  }
`;

const BottomRocket = styled(Parallax)`
  position: absolute;
  top: 60%;
  right: 130px;
  z-index: 0;
  width: 204px;
  height: 116px;

  background-image: url('${S3_ASSET_HOST_URL}/front/image/privacy/bottom-rocket.webp');
  background-size: 100%;
  background-repeat: no-repeat;

  ${media.lg} {
    right: -20px;
    width: 162px;
    height: 92px;
  }

  ${media.md} {
    width: 125px;
    height: 74px;
  }
`;

const BottomIce = styled.div`
  position: absolute;
  right: -50px;
  bottom: -60px;
  text-align: right;

  & > img.character {
    position: absolute;
    z-index: 1;
    top: -90px;
    right: 150px;
    width: 88px;
    height: 111px;

    ${media.xs} {
      right: 80px;
    }
  }

  & > img.ice {
    width: 700px;
    height: 300px;
    image-rendering: pixelated;
    z-index: 0;

    ${media.md} {
      height: 250px;
    }

    ${media.sm} {
      width: 570px;
      height: 210px;
    }

    ${media.xs} {
      width: 90%;
    }
  }
`;

export default Privacy;
